<template>
<div
    v-show="showCreateSchoolAnnouncement"
    ref="schoolAnnouncementContainer"
    class="grouped-modal"
>
    <div class="d-flex flex-row">
        <div
            v-if="showRecipientsList"
            class="modal modal-sticky-bottom-right modal-sticky-lg show mx-1"
            role="dialog"
            data-backdrop="false"
            aria-modal="true"
            style="display: inline-block; overflow: visible; position: relative;"
            :style="{ maxWidth: hasRecipients ? '36vw' : '28vw', width: hasRecipients ? '36vw' : '28vw' }"
        >
            <b-overlay
                :variant="'transparent'"
                center
                opacity="1"
                blur="10px"
                :show="encryptionEnabled"
                style="height: 100%;"
            >
                <div
                    class="modal-dialog"
                    role="document"
                    style="height: 100%;"
                >
                    <div class="modal-content" style="height: 100%;">
                        <div class="kt-portlet kt-portlet--mobile" style="height: 100%;">
                            <div class="kt-portlet__head">
                                <div class="kt-portlet__head-label">
                                    <h3 class="kt-portlet__head-title">
                                        Select Recipients
                                    </h3>
                                </div>
                            </div>
                            <div class="kt-portlet__body" style="height: 100%;">
                                <div class="kt-widget4 kt-scroll" style="height: 52vh; overflow-x: hidden; overflow-y: auto;">
                                    <div class="kt-widget4__item">
                                        <div class="kt-widget4__pic kt-widget4__pic--pic d-flex align-items-center">
                                            <label class="kt-checkbox kt-checkbox--solid kt-checkbox--single mt-2">
                                                <input
                                                    type="checkbox"
                                                    :checked="newAnnouncement.isSchoolAnnouncement"
                                                    @change="setSchoolAnnouncement($event)"
                                                >
                                                <span />
                                            </label>
                                            <span
                                                class="btn btn-icon"
                                                style="cursor: default !important;"
                                                :class="[ 'Purple', `btn-sm` ]"
                                            >
                                                <span
                                                    class="course-icon"
                                                    :style="[{color: '#007bff'}]"
                                                >
                                                    <i class="fa fa-school" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="kt-widget4__info">
                                            <span class="kt-widget4__username kt-font-bolder">
                                                Entire School
                                            </span>
                                        </div>
                                        <div v-if="newAnnouncement.isSchoolAnnouncement" class="col-7">
                                            <label>Send to</label>
                                            <div class="kt-checkbox-inline">
                                                <label v-if="userPermissions.canMessageSchoolUsers" class="kt-checkbox">
                                                    <input v-model="newAnnouncement.includeStaff" type="checkbox">Staff
                                                    <span />
                                                </label>
                                                <label v-if="userPermissions.canMessageHomeUsers" class="kt-checkbox">
                                                    <input v-model="newAnnouncement.includeStudents" type="checkbox">Students
                                                    <span />
                                                </label>
                                                <label v-if="userPermissions.canMessageHomeUsers" class="kt-checkbox">
                                                    <input v-model="newAnnouncement.includeGuardians" type="checkbox">Guardians
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-for="(gradeLevel, idx) in gradeLevels"
                                        :key="`gradeLevel_${idx}`"
                                        class="kt-widget4__item"
                                    >
                                        <div class="kt-widget4__pic kt-widget4__pic--pic d-flex align-items-center">
                                            <label class="kt-checkbox kt-checkbox--solid kt-checkbox--single mt-2">
                                                <input
                                                    type="checkbox"
                                                    :checked="Boolean(newAnnouncement.groupings.gradeLevels.find((g) => g.gradeLevel == gradeLevel))"
                                                    @change="gradeLevelChecked($event, gradeLevel)"
                                                >
                                                <span />
                                            </label>
                                            <span
                                                class="btn btn-icon"
                                                style="cursor: default !important;"
                                                :class="[ 'Blue', `btn-sm` ]"
                                            >
                                                <span
                                                    class="course-icon"
                                                    :style="[{color: '#007bff'}]"
                                                >
                                                    {{ gradeLevel }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="kt-widget4__info">
                                            <span class="kt-widget4__username">
                                                {{ gradeLevel }}
                                            </span>
                                            <p class="kt-widget4__text">
                                                Grade Level
                                            </p>
                                        </div>
                                        <div v-if="Boolean(newAnnouncement.groupings.gradeLevels.find((g) => g.gradeLevel == gradeLevel))" class="col-7">
                                            <label>Send to</label>
                                            <div class="kt-checkbox-inline">
                                                <label class="kt-checkbox">
                                                    <input v-model="newAnnouncement.groupings.gradeLevels.find((g) => g.gradeLevel == gradeLevel).includeStudents" type="checkbox">Students
                                                    <span />
                                                </label>
                                                <label class="kt-checkbox">
                                                    <input v-model="newAnnouncement.groupings.gradeLevels.find((g) => g.gradeLevel == gradeLevel).includeGuardians" type="checkbox">Guardians
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-for="(homeRoom, idx) in homeRooms"
                                        :key="`homeRoom_${idx}`"
                                        class="kt-widget4__item"
                                    >
                                        <div class="kt-widget4__pic kt-widget4__pic--pic d-flex align-items-center">
                                            <label class="kt-checkbox kt-checkbox--solid kt-checkbox--single mt-2">
                                                <input
                                                    type="checkbox"
                                                    :checked="Boolean(newAnnouncement.groupings.homeRooms.find((h) => h.homeRoom == homeRoom))"
                                                    :disabled="newAnnouncement.isSchoolAnnouncement"
                                                    @change="homeRoomChecked($event, homeRoom)"
                                                >
                                                <span />
                                            </label>
                                            <span
                                                class="btn btn-icon"
                                                style="cursor: default !important;"
                                                :class="[ 'Blue', `btn-sm` ]"
                                            >
                                                <span
                                                    class="course-icon"
                                                    :style="[{color: '#007bff'}]"
                                                >
                                                    {{ homeRoom.substring(0, 1) }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="kt-widget4__info">
                                            <span class="kt-widget4__username">
                                                {{ homeRoom }}
                                            </span>
                                            <p class="kt-widget4__text">
                                                Homeroom
                                            </p>
                                        </div>
                                        <div v-if="Boolean(newAnnouncement.groupings.homeRooms.find((h) => h.homeRoom == homeRoom))" class="col-7">
                                            <label>Send to</label>
                                            <div class="kt-checkbox-inline">
                                                <label class="kt-checkbox">
                                                    <input v-model="newAnnouncement.groupings.homeRooms.find((h) => h.homeRoom == homeRoom).includeStudents" type="checkbox">Students
                                                    <span />
                                                </label>
                                                <label class="kt-checkbox">
                                                    <input v-model="newAnnouncement.groupings.homeRooms.find((h) => h.homeRoom == homeRoom).includeGuardians" type="checkbox">Guardians
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
        <div
            id="kt_inbox_compose"
            class="modal modal-sticky-bottom-right modal-sticky-lg show mx-1"
            role="dialog"
            data-backdrop="false"
            aria-modal="true"
            style="display: block; overflow: visible; position: relative; width: 40vw;"
        >
            <b-overlay
                :variant="'transparent'"
                no-center
                opacity="1"
                blur="10px"
                :show="encryptionEnabled"
            >
                <template #overlay>
                    <div class="pull-right mr-2 mt-2 kt-font-lg">
                        <button
                            type="button"
                            class="btn btn-icon btn-clean"
                            @click.stop.prevent="closeModal"
                        >
                            <i class="flaticon2-cross" />
                        </button>
                    </div>
                    <div class="text-center pt-4 kt-font-lg">
                        PII is currently encrypted. Please unlock PII to send an announcement.
                    </div>
                    <div class="text-center pt-4">
                        <button
                            v-if="encryptionEnabled"
                            type="button"
                            style="min-width: 160px;"
                            class="btn btn-label-brand btn-bold btn-upper mt-4"
                            @click="decrypt()"
                        >
                            Unlock PII
                        </button>
                    </div>
                </template>
                <div class="modal-dialog" role="document">
                    <div class="modal-content kt-inbox">
                        <div id="kt_inbox_compose_form" class="kt-inbox__form">
                            <div class="kt-inbox__head" @mousedown="dragMouseDown">
                                <div class="kt-inbox__title">
                                    New School Announcement
                                </div>
                                <div class="kt-inbox__actions">
                                    <button
                                        v-if="hasContent || hasRecipients || files.length"
                                        v-b-tooltip.hover="'Discard message'"
                                        class="kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light"
                                        @click.stop.prevent="clearMessage"
                                    >
                                        <i class="flaticon2-rubbish-bin-delete-button" />
                                    </button>
                                    <button
                                        class="btn btn-sm btn-icon btn-clean btn-icon-md"
                                        @click.stop.prevent="toggleSize"
                                    >
                                        <i :class="['la', minimized ? 'la-angle-up' : 'la-angle-down' ]" />
                                    </button>
                                    <button
                                        type="button"
                                        class="kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light"
                                        @click.stop.prevent="closeModal"
                                    >
                                        <i class="flaticon2-cross" />
                                    </button>
                                </div>
                            </div>
                            <div
                                class="kt-inbox__body"
                                :class="[minimized ? 'd-none' : '']"
                                style="position: relative;"
                            >
                                <div class="kt-inbox__to">
                                    <div class="kt-inbox__wrapper">
                                        <div class="kt-inbox__field kt-inbox__field--to">
                                            <div class="kt-inbox__label">
                                                To:
                                            </div>
                                            <a
                                                href="#"
                                                class="kt-link kt-link--state kt-link--primary"
                                                @click.stop.prevent="showRecipientsList = !showRecipientsList"
                                            >
                                                {{ newAnnouncement.isSchoolAnnouncement ? '1' : `${newAnnouncement.groupings.gradeLevels.length + newAnnouncement.groupings.homeRooms.length}` }} Recipients
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-inbox__subject ql-toolbar ql-snow">
                                    <input
                                        v-model="newAnnouncement.subject"
                                        class="form-control"
                                        placeholder="Subject"
                                    >
                                </div>
                                <div
                                    id="kt_inbox_compose_editor"
                                    class="kt-inbox__editor ql-container ql-snow px-3 pt-3"
                                    style="height: 38vh"
                                >
                                    <textarea
                                        v-model="newAnnouncement.body"
                                        class="w-100 h-100"
                                        :style="[files.length ? { paddingBottom: `${files.length * 40}px`} : {}, ]"
                                        placeholder="Type message..."
                                        style="border: none !important; outline: none !important; resize: none !important;"
                                    />
                                </div>
                                <div class="w-100 px-3" style="position: absolute; bottom: 5px;">
                                    <div
                                        v-for="(file, idx) in files"
                                        :key="`attachedFile_${idx}`"
                                        class="attached-file px-3 py-1 my-2 d-flex align-items-center"
                                    >
                                        <span class="kt-link kt-link--state kt-link--primary kt-font-bolder no-wrap">
                                            {{ file.name }}
                                        </span>
                                        <a
                                            href="#"
                                            class="kt-link kt-link--state kt-link--danger kt-font-bolder ml-auto"
                                            @click.stop.prevent="files = files.filter((f, i) => i !== idx)"
                                        >
                                            <i class="flaticon2-cross" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-inbox__foot" :class="[minimized ? 'd-none' : '']">
                                <div class="kt-inbox__primary">
                                    <button
                                        type="button"
                                        class="btn btn-brand btn-bold mr-3"
                                        :class="{ 'kt-spinner  kt-spinner--sm kt-spinner--light': saving }"
                                        :disabled="!canSend || saving"
                                        @click.stop.prevent="sendAnnouncement"
                                    >
                                        Send
                                    </button>
                                    <span v-if="canSend && newAnnouncement.isSchoolAnnouncement">
                                        <span class="kt-font-warning kt-font-bolder">
                                            Announcements are irreversible once sent.
                                        </span>
                                        <div
                                            v-if="newAnnouncement.smsEnabled && canToggleSMS"
                                            v-b-tooltip.hover="characterCount > 2400 ? 'Any text past 2400 characters will not be sent with SMS' : characterCount > 1200 ? 'SMS will be sent as 2 separate messages' : ''"
                                            :class="{'kt-font-warning kt-font-bolder': characterCount > 1200 && characterCount <= 2400, 'kt-font-danger kt-font-bolder': characterCount > 2400}"
                                        >
                                            {{ characterCount }} / 2400 characters for SMS
                                        </div>
                                    </span>
                                    <span
                                        v-else-if="newAnnouncement.smsEnabled && canToggleSMS"
                                        v-b-tooltip.hover="characterCount > 2400 ? 'Any text past 2400 characters will not be sent with SMS' : characterCount > 1200 ? 'SMS will be sent as 2 separate messages' : ''"
                                        :class="{'kt-font-warning kt-font-bolder': characterCount > 1200 && characterCount <= 2400, 'kt-font-danger kt-font-bolder': characterCount > 2400}"
                                    >
                                        {{ characterCount }} / 2400 characters for SMS
                                    </span>
                                </div>
                                <div class="kt-inbox__secondary">
                                    <button
                                        v-b-tooltip.hover="'Allow recipients to reply to message'"
                                        type="button"
                                        class="btn btn-bold btn-icon mx-1"
                                        :class="{ 'btn-outline-success': !newAnnouncement.allowReplies, 'btn-success': newAnnouncement.allowReplies }"
                                        @click.stop.prevent="newAnnouncement.allowReplies = !newAnnouncement.allowReplies"
                                    >
                                        <i class="fa fa-reply" />
                                    </button>
                                    <span v-b-tooltip.hover="subscription ? subscription.emailQuota !== null&& (subscription.emailUsage >= subscription.emailQuota) ? 'Your school\'s email limit has been exceeded' : 'Notify email of recipients' : 'Notify email of recipients'">
                                        <button
                                            v-if="canToggleEmail"
                                            type="button"
                                            class="btn btn-bold btn-icon mx-1"
                                            :class="{ 'btn-outline-success': !newAnnouncement.notifyEmail, 'btn-success': newAnnouncement.notifyEmail }"
                                            :disabled="subscription ? subscription.emailQuota !== null && (subscription.emailUsage >= subscription.emailQuota) : false"
                                            @click.stop.prevent="newAnnouncement.notifyEmail = !newAnnouncement.notifyEmail"
                                        >
                                            <i class="flaticon2-new-email" />
                                        </button>
                                    </span>
                                    <span v-b-tooltip.hover="subscription ? subscription.smsQuota !== null && (subscription.smsUsage >= subscription.smsQuota) ? 'Your school\'s SMS limit has been exceeded' : 'Send SMS to recipients' : 'Send SMS to recipients'">
                                        <button
                                            v-if="canToggleSMS"
                                            type="button"
                                            class="btn btn-bold btn-icon mx-1"
                                            :class="{ 'btn-outline-warning': !newAnnouncement.smsEnabled, 'btn-warning': newAnnouncement.smsEnabled }"
                                            :disabled="subscription ? subscription.smsQuota !== null && (subscription.smsUsage >= subscription.smsQuota) : false"
                                            @click.stop.prevent="newAnnouncement.smsEnabled = !newAnnouncement.smsEnabled"
                                        >
                                            <i class="fa fa-mobile-alt" />
                                        </button>
                                    </span>
                                    <div>
                                        <input
                                            id="file-input"
                                            type="file"
                                            name="file"
                                            style="display: none"
                                            @change="onFileBrowseSelect"
                                        >
                                        <button
                                            v-b-tooltip.hover="'Attach up to 3 files to message'"
                                            type="button"
                                            class="btn btn-bold btn-icon btn-clean btn-clean-dark mx-1"
                                            :disabled="files.length >= 3"
                                            @click.stop.prevent="triggerFileBrowser"
                                        >
                                            <i class="fa fa-paperclip" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import async from 'async';
import Types from '../../store/Types';
import userMixins from '../../store/mixins/userMixins';
import { getStudentGradeLevels, getStudentHomeRooms } from '../../store/mixins/studentMixins';
import * as network from '../../network';
import * as util from '../../lib/uploads/util';

export default {
    name: 'CreateSchoolAnnouncement',
    components: {},
    mixins: [userMixins],
    data() {
        return {
            key: 0,
            showRecipientsList: false,
            files: [],
            newAnnouncement: {
                subject: '',
                body: '',
                groupings: {
                    courses: [],
                    homeRooms: [],
                    gradeLevels: [],
                    studentGroups: [],
                },
                allowReplies: false,
                notifyEmail: false,
                smsEnabled: false,
                isSchoolAnnouncement: false,
                includeStaff: false,
                includeStudents: false,
                includeGuardians: false,
                attachments: [],
            },
            saving: false,
            minimized: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            showCreateSchoolAnnouncement: (state) => state.communication.createSchoolAnnouncement,
            userPermissions: (state) => state.user.userPermissions,
        }),
        school() {
            const { user } = this;
            const { school } = user;
            const { schoolId } = school;

            return this.$store.state.database.schools.find((s) => s.schoolId == schoolId) || null;
        },
        subscription() {
            const { user, school } = this;
            if (!school || !user.school) return null;

            return school.subscriptions.find((s) => s.schoolYear == user.school.schoolYear) || null;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        hasContent() {
            const { newAnnouncement } = this;
            return Boolean(newAnnouncement.subject || newAnnouncement.body);
        },
        hasRecipients() {
            const { newAnnouncement } = this;
            const { groupings } = newAnnouncement;
            return (newAnnouncement.isSchoolAnnouncement && (newAnnouncement.includeStaff || newAnnouncement.includeStudents || newAnnouncement.includeGuardians))
                || groupings.homeRooms.length || groupings.gradeLevels.length;
        },
        canSend() {
            const { newAnnouncement, encryptionEnabled, hasContent, hasRecipients } = this;
            if (encryptionEnabled) return false;
            return hasContent && hasRecipients;
        },
        canToggleEmail() {
            const { userPermissions } = this;
            if (!userPermissions.canNotifyEmail) return false;
            return true;
        },
        canToggleSMS() {
            const { userPermissions, newAnnouncement } = this;
            if (!userPermissions.canNotifySms) return false;

            const { isSchoolAnnouncement, includeGuardians, groupings } = newAnnouncement;
            if (isSchoolAnnouncement) return includeGuardians;

            for (const [, value] of Object.entries(groupings)) {
                if (value.length && value.some((v) => v.includeGuardians)) return true;
            }
            return false;
        },
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId || null;
        },
        homeRooms() {
            const { userPermissions } = this;
            const { canMessageHomeUsers } = userPermissions;
            if (!this.$_userMixins_isSchoolAdmin || !canMessageHomeUsers) return [];

            const { database } = this.$store.state;
            return getStudentHomeRooms(database).sort((a, b) => a.localeCompare(b));
        },
        gradeLevels() {
            const { userPermissions } = this;
            const { canMessageHomeUsers } = userPermissions;
            if (!this.$_userMixins_isSchoolAdmin || !canMessageHomeUsers) return [];

            const { database } = this.$store.state;
            return getStudentGradeLevels(database).sort((a, b) => a.localeCompare(b));
        },
        characterCount() {
            const { newAnnouncement } = this;
            return newAnnouncement.subject.length + newAnnouncement.body.length;
        },
    },
    watch: {
        canToggleEmail: {
            handler() {
                const { userPermissions } = this;
                if (!userPermissions.canNotifyEmail) this.newAnnouncement.notifyEmail = false;
            },
            immediate: true,
        },
        canToggleSMS: {
            handler() {
                const { userPermissions } = this;
                if (!userPermissions.canNotifySms) this.newAnnouncement.smsEnabled = false;
            },
            immediate: true,
        },
        subscription: {
            handler() {
                const { subscription } = this;
                if (subscription) {
                    const { emailQuota, emailUsage, smsQuota, smsUsage } = subscription;
                    if (emailQuota !== null && (emailUsage >= emailQuota)) {
                        this.newMessage.notifyEmail = false;
                    }
                    if (smsQuota !== null && (smsUsage >= smsQuota)) {
                        this.newMessage.smsEnabled = false;
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        closeModal() {
            this.clearMessage();
            this.$store.commit(Types.mutations.TOGGLE_CREATE_SCHOOL_ANNOUNCEMENT);
            this.minimized = false;
        },
        toggleSize() {
            this.minimized = !this.minimized;

            if (this.minimized) {
                this.showRecipientsList = false;
                this.positions.movementX = undefined;
                this.positions.movementY = undefined;
                this.positions.clientX = 0;
                this.positions.clientY = 0;
                this.$refs.schoolAnnouncementContainer.style.top = 'auto';
                this.$refs.schoolAnnouncementContainer.style.left = 'auto';
            }
        },
        dragMouseDown(event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;

            // set the element's new position:
            this.$refs.schoolAnnouncementContainer.style.top = `${this.$refs.schoolAnnouncementContainer.offsetTop - this.positions.movementY}px`;
            this.$refs.schoolAnnouncementContainer.style.left = `${this.$refs.schoolAnnouncementContainer.offsetLeft - this.positions.movementX}px`;
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        onFileBrowseSelect(e) {
            const { files } = this;
            const [file] = e.target.files;

            if (!file) return;
            if (files.length >= 3) return this.showError('You can not attach more than 3 files to a message');
            if (file.size > 52428800) return this.showError('File size must be less than 50MB');

            this.files.push(file);
            e.target.value = '';
        },
        sendAnnouncement() {
            const v = this;
            if (v.saving || !v.canSend) return;
            v.saving = true;

            const { user, newAnnouncement } = v;
            const { school } = user;
            const { schoolId, schoolTermId } = school;

            async.auto({
                uploadAttachments(next) {
                    async.eachLimit(v.files, 5, (file, nextFile) => {
                        util.uploadToAWS('message-attachments', file, user, (err, results) => {
                            if (err) return nextFile(err);
                            const { parameters } = results;
                            const { key } = parameters.fields;
                            if (!key) return nextFile(new Error('Attachment upload failed'));

                            newAnnouncement.attachments.push({
                                fileName: file.name,
                                fileBucketPath: `https://lilo-app.s3.amazonaws.com/${key}`,
                            });
                            return nextFile(err, results);
                        });
                    }, next)
                },
                sendMessage: ['uploadAttachments', (results, next) => {
                    const params = {
                        url: {
                            schoolId,
                            schoolTermId,
                        },
                        body: {
                            announcement: { ...newAnnouncement, subject: newAnnouncement.subject.trim(), body: newAnnouncement.body.trim() },
                        },
                    };

                    network.communication.createAnnouncement(params, (err, res) => {
                        if (err) return next(err);
                        const { message, emailsQueued } = res;
                        return next(null, { message, emailsQueued });
                    });
                }],
            }, 5, (err, results) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { sendMessage } = results;
                const { message, emailsQueued } = sendMessage;
                if (message) {
                    v.showNotification(`Message sent ${emailsQueued ? 'and emails queued' : ''}`);
                    v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, messageRead: true, isOwner: true }]);
                    v.closeModal();
                }
            });
        },
        setSchoolAnnouncement(e) {
            const { userPermissions } = this;
            const { canMessageSchoolUsers, canMessageHomeUsers } = userPermissions;
            if (e.target.checked) {
                this.newAnnouncement.isSchoolAnnouncement = true;
                this.newAnnouncement.includeStaff = canMessageSchoolUsers;
                this.newAnnouncement.includeStudents = canMessageHomeUsers;
                this.newAnnouncement.groupings.homeRooms = [];
                this.newAnnouncement.groupings.gradeLevels = [];
            } else {
                this.newAnnouncement.isSchoolAnnouncement = false;
                this.newAnnouncement.includeStaff = false;
                this.newAnnouncement.includeStudents = false;
                this.newAnnouncement.includeGuardians = false;
            }
        },
        homeRoomChecked(e, homeRoom) {
            if (e.target.checked && !this.newAnnouncement.groupings.homeRooms.find((h) => h.homeRoom == homeRoom)) {
                this.newAnnouncement.groupings.homeRooms.push({
                    homeRoom,
                    includeStudents: true,
                    includeGuardians: false,
                });
            } else {
                this.newAnnouncement.groupings.homeRooms = this.newAnnouncement.groupings.homeRooms.filter((h) => h.homeRoom != homeRoom);
            }
        },
        gradeLevelChecked(e, gradeLevel) {
            if (e.target.checked && !this.newAnnouncement.groupings.gradeLevels.find((g) => g.gradeLevel == gradeLevel)) {
                this.newAnnouncement.groupings.gradeLevels.push({
                    gradeLevel,
                    includeStudents: true,
                    includeGuardians: false,
                });
            } else {
                this.newAnnouncement.groupings.gradeLevels = this.newAnnouncement.groupings.gradeLevels.filter((g) => g.gradeLevel != gradeLevel);
            }
        },
        clearMessage() {
            this.files = [];
            this.newAnnouncement = {
                subject: '',
                body: '',
                groupings: {
                    courses: [],
                    homeRooms: [],
                    gradeLevels: [],
                    studentGroups: [],
                },
                allowReplies: false,
                notifyEmail: false,
                smsEnabled: false,
                isSchoolAnnouncement: false,
                includeStaff: false,
                includeStudents: false,
                includeGuardians: false,
                attachments: [],
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/pages/inbox/inbox.scss";

.grouped-modal {
    z-index: 100;
    position: fixed;
    left: auto;
    bottom: 5px;
    top: auto;
    right: 5px;
}

.attached-file {
    box-shadow: 0px 0px 8px 0px #bababa;
    border-radius: 4px;
    background-color: white;
}
</style>
