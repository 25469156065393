<template>
<div class="kt-widget20">
    <div class="kt-widget20__content pt-0 mt-0">
        <div
            class="media"
        >
            <div :class="`mr-3 align-self-center kt-badge kt-badge--lg scaleColorBadge kt-font-lg kt-font-bold ${color}`">
                {{ averageDisplay }}
            </div>
            <div class="media-body">
                <span :class="`kt-widget20__number font kt-font-lg ${color}`">
                    {{ label }}
                </span>
                <br>
                <span class="kt-widget20__desc kt-font-bolder">
                    {{ percentage }}%
                </span>
            </div>
        </div>
    </div>
</div>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'StudentMarkingPeriodAverage',
    props: {
        percentage: {
            type: Number,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        averageDisplay: {
            type: String,
            required: true,
        },
    },
});
</script>
<style scoped>

</style>
