<template>
<div
    v-if="decimalToPercent(markingPeriodAverage.markingPeriodAverage) !== null"
    class="kt-widget20"
>
    <div class="kt-widget20__content kt-portlet__space-x pb-0">
        <div class="row">
            <div class="col-6">
                <StudentMarkingPeriodAverage
                    :percentage="decimalToPercent(markingPeriodAverage.markingPeriodAverage)"
                    :color="markingPeriodAverage.color"
                    :label="`MP${markingPeriodAverage.markingPeriod.markingPeriod} Avg`"
                    :average-display="markingPeriodAverage.mark"
                />
            </div>
            <div class="col-6">
                <div
                    class="media"
                    :style="{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }"
                >
                    <UserAvatar
                        :avatar-user="student"
                        style="max-height: 38px;"
                        class="kt-widget7__img mx-3 my-0 "
                    >
                        <div class="kt-media">
                            <span class="kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold">
                                {{ initials }}
                            </span>
                        </div>
                    </UserAvatar>
                    <div :class="'media-body'">
                        <div class="text-nowrap text-truncate">
                            <router-link
                                class="kt-widget5__title pb-0 w-100"
                                event=""
                                :to="{
                                    name: 'StudentCourseOverview',
                                    params: {
                                        studentEnrollmentId,
                                        extCourseSectionId: course.extCourseSectionId
                                    },
                                }"
                                @click.native.prevent="openPanelForStudent(student)"
                            >
                                {{ student.lastName }}, {{ student.firstName }}
                            </router-link>
                            <div :class="`kt-font kt-font-transform-u ${courseWork.color} `">
                                {{ courseWork.categoryName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                v-for="c in categories"
                :key="c.categoryName"
                class="col-4"
            >
                <StudentMarkingPeriodAverageTotals
                    v-if="c.scaled && decimalToPercent(c.percentEarned) !== null"
                    :percentage="decimalToPercent(c.percentEarned)"
                    :color="c.scaled.color"
                    :label-color="c.color"
                    :label="c.categoryName"
                    :average-display="c.scaled.mark"
                />
            </div>
        </div>
    </div>
    <div class="kt-widget20__chart">
        <GradebookFooterLineChart
            :key="`${courseSectionId}_${studentEnrollmentId}_${schoolTermMarkingPeriodId}_${selectedGradingCategory}`"
            :height="40"
            :assignments="assignments"
            :category="category"
            :clicked-assignment="openPanelForCourseWork"
        />
    </div>
</div>
</template>

<script lang="ts">
import studentMixins from '../store/mixins/studentMixins';
import courseMixins from '../store/mixins/courseMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import panelMixins from '../store/mixins/panelMixins';
import UserAvatar from './UserAvatar.vue';
import GradebookFooterLineChart from './GradebookFooterLineChart.vue';
import StudentMarkingPeriodAverage from './StudentMarkingPeriodAverage.vue';
import StudentMarkingPeriodAverageTotals from './StudentMarkingPeriodAverageTotals.vue';

export default {
    name: 'StudentMarkingPeriodAveragePortlet',
    components: {
        StudentMarkingPeriodAverage,
        StudentMarkingPeriodAverageTotals,
        GradebookFooterLineChart,
        UserAvatar,
    },
    mixins: [
        studentMixins,
        courseMixins,
        teacherMixins,
        panelMixins,
    ],
    computed: {
        student() {
            if (!this.studentEnrollmentId) return null;
            return this.$_studentMixins_getStudentInCourse(this.studentEnrollmentId, this.courseSectionId);
        },
        initials() {
            if (!this.student) return '';
            return this.student.lastName.substring(0, 2);
        },
        markingPeriodAverage() {
            const { schoolTermMarkingPeriodId, student } = this;
            if (!student) return null;
            return (student.markingPeriodAverages || []).find((a) => a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        averageCalculation() {
            const { markingPeriodAverage } = this;
            if (!markingPeriodAverage) return null;
            const { markingPeriod } = markingPeriodAverage.averageCalculation;
            return markingPeriod || [];
        },
        assignments() {
            const { schoolTermMarkingPeriodId, averageCalculation, selectedGradingCategory } = this;
            const unique = new Map();
            (averageCalculation.categories || []).forEach((category) => {
                category.assignments.forEach((a) => {
                    const assignment = { ...a };

                    assignment.assignedGrade = ''; // a.grade.syncGrades.assignedGrade;
                    assignment.graded = false;

                    if (a.grade && a.grade.syncGrades) {
                        assignment.assignedGrade = a.grade.syncGrades.assignedGrade;
                        assignment.averageMethod = a.grade.syncGrades.averageMethod;
                        assignment.graded = true;

                        if (a.grade.syncGrades.scaled && a.grade.syncGrades.scaled.points) {
                            assignment.points = a.grade.syncGrades.scaled.points;
                            assignment.pointsColor = a.grade.syncGrades.scaled.points.color;
                            assignment.pointsPossible = a.grade.syncGrades.pointsPossible;
                            assignment.pointsEarned = a.grade.syncGrades.pointsEarned;
                            assignment.percentColor = a.grade.syncGrades.scaled.percent.color;
                            assignment.percentEarned = a.grade.syncGrades.percentEarned;
                            assignment.scaledPointsMark = a.grade.syncGrades.scaled.points.mark || null;
                            assignment.scaledPercentMark = a.grade.syncGrades.scaled.percent.mark || null;
                        } else { // excused grade
                            assignment.pointsColor = a.grade.syncGrades.color;
                            assignment.percentColor = a.grade.syncGrades.color;
                        }
                    }
                    if (a.googleCourseWorkId) {
                        assignment.publishDate = a.sortDate.substring(0, 10);
                        if (a.dueDate) {
                            assignment.dueDate = a.dueDate.substring(0, 10);
                        }
                    }
                    assignment.categoryName = category.categoryName;
                    assignment.categoryColor = category.color;

                    const key = assignment.googleCourseWorkId ? `gcw_${assignment.googleCourseWorkId}` : `cw_${assignment.courseWorkId}`;
                    if (assignment.categoryName == selectedGradingCategory || selectedGradingCategory == 'Overall') {
                        if (assignment.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId && !assignment.excluded) {
                            unique.set(key, assignment);
                        }
                    }
                });
            });
            return [...unique.values()];
        },
        selectedGradingCategory() {
            // if (!this.courseWork) return null;
            // return this.courseWork.categoryName;
            return 'Overall';
        },
        category() {
            const { selectedGradingCategory } = this;
            if (!selectedGradingCategory) return null;
            if (selectedGradingCategory === 'Overall') {
                return {
                    categoryName: 'Overall',
                    scaled: this.averageCalculation.scaled,
                };
            }
            return this.categories.find((cat) => cat.categoryName == selectedGradingCategory);
        },
        categories() {
            const { averageCalculation } = this;
            if (!averageCalculation) return [];
            return (averageCalculation.categories || []).filter((c) => c && c.scaled && c.assignments.length > 0);
        },
        activeCell() {
            return this.$store.state.database.gradebook.activeCell;
        },
        linkingGuid() {
            return this.activeCell.linkingGuid;
        },
        courseWorkDomain() {
            return this.activeCell.courseWorkDomain;
        },
        studentEnrollmentId() {
            return this.activeCell.studentEnrollmentId;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        courseSectionId() {
            if (!this.course) return null;
            return this.course.courseSectionId;
        },
        courseWork() {
            const { linkingGuid, courseWorkDomain } = this;
            if (!this.course) return null;
            const { courseSectionId } = this.course;
            if (courseWorkDomain == 'Local') {
                return this.$store.state.database.courseWork.find((cw) => cw.linkingGuid == linkingGuid && cw.courseSectionId == courseSectionId) || null;
            }
            if (courseWorkDomain == 'Google') {
                return this.$store.state.database.googleCourseWork.find((cw) => cw.linkingGuid == linkingGuid && cw.courseSectionId == courseSectionId) || null;
            }
            return null;
        },
    },
    methods: {
        openPanelForCourseWork(courseWork) {
            this.$_panelMixins_openPanelForCourseWork(courseWork);
        },
        openPanelForStudent(student) {
            const { course } = this;
            this.$_panelMixins_openPanelForStudent(student, course);
        },
        decimalToPercent(v) {
            let value = v;
            if (value === 0) return 0;
            if (!value) return null;
            value *= 100;
            const decimals = 2;
            // @ts-ignore
            return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
        },
    },
};

</script>

<style lang="scss" scoped>
.modal-sticky-md {
    position: absolute !important;
    height: fit-content !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px  !important;
}
</style>
