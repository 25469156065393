var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        {
          key: `avg_${_vm.key}_${_vm.course.courseSectionId}`,
          staticClass:
            "d-flex flex-column no-gutters align-items-center justify-content-center",
          staticStyle: { height: "100%" },
        },
        [
          _vm.displayPreference === "All" ||
          _vm.displayPreference === "ShowPercentage"
            ? _c("div", { class: `grade font ${_vm.color}` }, [
                _vm._v(" " + _vm._s(_vm.markingPeriodAverage) + " "),
              ])
            : _vm._e(),
          _vm.displayPreference === "All" ||
          _vm.displayPreference === "ShowScale"
            ? _c(
                "div",
                {
                  class: [
                    `grade font ${_vm.color}`,
                    {
                      "mt-1": _vm.displayPreference === "All",
                    },
                  ],
                },
                [_vm._v(" " + _vm._s(_vm.mark) + " ")]
              )
            : _vm._e(),
          false
            ? _c("span", { staticClass: "text-muted" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      String(Math.floor(Date.now() / 1000) || "").substr(-4)
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }