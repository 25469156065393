<template>
<b-modal
    ref="google-import-modal"
    v-model="showModal"
    no-close-on-backdrop
    size="sm"
    centered
>
    <template #modal-header="{ close }">
        <h3 class="modal-title kt-font-primary">
            Google Classroom Assignment Import
        </h3>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="toggleShowGoogleImport"
        />
    </template>
    <template #default>
        <div class="modal-body" style="max-height: 70vh; overflow-y: scroll;">
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-section">
                    <div class="kt-section__content">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <td style="width:1%">
                                        #
                                    </td>
                                    <td style="width:20%">
                                        Title
                                    </td>
                                    <td style="width:10%">
                                        Max Points
                                    </td>
                                    <td style="width:12%">
                                        Marking Period
                                    </td>
                                    <td style="width:18%">
                                        Category
                                    </td>
                                    <td style="width:15%">
                                        Due Date
                                    </td>
                                    <td style="width: 35%">
                                        Update Existing
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(assignment, idx) in [...importedCourseWork]"
                                    :key="`imported_google_assignment_${idx}`"
                                >
                                    <td>
                                        <label class="kt-checkbox kt-checkbox--tick kt-checkbox--success">
                                            <input v-model="assignment.checked" type="checkbox"><span />
                                        </label>
                                    </td>
                                    <td>
                                        <span class="kt-widget11__title">{{ assignment.title }}</span>
                                    </td>
                                    <td>
                                        <input
                                            v-model="assignment.maxPoints"
                                            class="form-control"
                                            :class="{'is-invalid': invalidImport && assignment.checked && !assignment.maxPoints}"
                                            :disabled="!assignment.checked || assignment.isExistingCourseWork"
                                            type="text"
                                            style="width: 80%;"
                                        >
                                    </td>
                                    <td>
                                        <select
                                            v-model="assignment.schoolTermMarkingPeriodId"
                                            class="form-control"
                                            :disabled="!assignment.checked || assignment.isExistingCourseWork"
                                            :class="{'is-invalid': invalidImport && assignment.checked && !assignment.schoolTermMarkingPeriodId}"
                                            style="width: 80%;"
                                        >
                                            <option
                                                v-for="option in markingPeriodSelect"
                                                :key="option.value"
                                                :value="option.value"
                                            >
                                                {{ option.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            v-model="assignment.gradeTemplateCategoryId"
                                            class="form-control"
                                            :disabled="!assignment.checked || assignment.isExistingCourseWork"
                                            :class="{'is-invalid': invalidImport && assignment.checked && !assignment.gradeTemplateCategoryId}"
                                            style="width: 80%;"
                                        >
                                            <option
                                                v-for="t in gradeCategories"
                                                :key="`gradeTemplateCategories_${t.gradeTemplateCategoryId}`"
                                                :value="t.gradeTemplateCategoryId"
                                            >
                                                {{ t.categoryName }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <b-form-input
                                            v-model="assignment.dueDate"
                                            type="date"
                                            :disabled="!assignment.checked || assignment.isExistingCourseWork"
                                            :class="{'is-invalid': invalidImport && assignment.checked && !assignment.dueDate}"
                                            class="form-control kt-input"
                                            autocomplete="off"
                                            style="width: 80%;"
                                        />
                                    </td>
                                    <td :class="{'text-center': !hasExistingCourseWork}">
                                        <span class="row">
                                            <select
                                                v-model="assignment.courseWorkId"
                                                class="form-control"
                                                :disabled="!assignment.checked"
                                                style="width: 80%;"
                                                @change="existingCourseWorkSelected($event, idx)"
                                            >
                                                <option
                                                    :value="null"
                                                >
                                                    Import as new assignment
                                                </option>
                                                <option
                                                    v-for="option in filteredCourseWork"
                                                    :key="option.courseWorkId"
                                                    :value="option.courseWorkId"
                                                >
                                                    {{ option.courseWorkTitle }}
                                                </option>
                                            </select>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <div class="mr-auto ml-4">
            <div class="kt-font-bold row">
                <span class="form-text mr-3">
                    Select
                </span>
                <span class="form-text text-muted">
                    <a
                        href="#"
                        @click.stop.prevent="selectAll"
                    >All</a> |
                    <a
                        href="#"
                        @click.stop.prevent="selectNone"
                    >None</a> |
                    <a
                        href="#"
                        @click.stop.prevent="selectInvert"
                    >Invert</a>
                </span>
            </div>
        </div>
        <div>
            <span
                v-if="invalidImport"
                v-b-tooltip.hover="'Ensure all missing fields are entered'"
                class="mr-3"
            >
                <i class="fa fa-exclamation-circle fa-2x mt-2 kt-font-danger" />
            </span>
            <button
                type="button"
                class="btn btn-primary alignment pull-right"
                :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                data-dismiss="modal"
                :disabled="saving || noneSelected"
                @click.stop.prevent="importCourseWork"
            >
                Save Assignments
            </button>
        </div>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import async from 'async';
import { v4 as uuidv4 } from 'uuid';
import Types from '../store/Types';
import UserAvatar from './UserAvatar.vue';

export default {
    name: 'TheGradebookGoogleImportModal',
    components: {
        UserAvatar,
    },
    mixins: [],
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        googleImportData: {
            type: Array,
            required: true,
        },
        students: {
            type: Array,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        },
        courseWork: {
            type: Array,
            required: true,
        },
        gradebookSource: {
            type: Array,
            required: true,
        },
        setImportSource: {
            type: Function,
            required: true,
        },
        setImportingGrades: {
            type: Function,
            required: true,
        },
        toggleShowGoogleImport: {
            type: Function,
            required: true,
        },
        toggleShowImportExport: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            importedCourseWork: [],
            invalidImport: false,
            saving: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        showModal: {
            get() {
                return this.show;
            },
            set(val) {
                this.toggleShowGoogleImport();
            },
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        markingPeriods() {
            const { markingPeriods } = this.$store.state.database;
            if (!markingPeriods || !markingPeriods.length) return [];
            return markingPeriods
                .filter((mp) => !mp.deleted)
                .sort((a, b) => ((a.markingPeriod > b.markingPeriod) ? 1 : ((b.markingPeriod > a.markingPeriod) ? -1 : 0)));
        },
        markingPeriodSelect() {
            const { markingPeriods } = this;
            const mps = markingPeriods.filter((mp) => !mp.deleted).map((mp) => ({
                value: mp.schoolTermMarkingPeriodId,
                text: mp.markingPeriod,
            }));
            mps.unshift({
                value: null,
                text: 'No Marking Period',
            });
            return mps;
        },
        gradeTemplate() {
            const { course } = this;
            if (!course) return null;
            return course.gradeTemplate || null;
        },
        gradeCategories() {
            const { gradeTemplate } = this;
            if (!gradeTemplate) return [];

            const categories = gradeTemplate.categories.slice();
            categories.unshift({
                gradeTemplateCategoryId: 0,
                categoryName: 'No Category',
            });

            return categories;
        },
        filteredCourseWork() {
            const { courseWork } = this;
            const unique = new Map();
            courseWork.forEach((cw) => {
                unique.set(cw.courseWorkId, cw);
            });

            return [...unique.values()].filter((cw) => !cw.deleted && cw.courseWorkId).sort((a, b) => ((a.courseWorkTitle > b.courseWorkTitle) ? 1 : ((b.courseWorkTitle > a.courseWorkTitle) ? -1 : 0)));

        },
        hasExistingCourseWork() {
            return this.importedCourseWork.some((cw) => cw.isExistingCourseWork);
        },
        noneSelected() {
            return this.importedCourseWork.every((cw) => !cw.checked);
        },
        canImport() {
            const { noneSelected, importedCourseWork } = this;
            if (!importedCourseWork || !importedCourseWork.length) return false;
            if (noneSelected) return false;

            const allHaveMaxPoints = importedCourseWork.filter((cw) => cw.checked).every((cw) => cw.maxPoints);
            const allHaveMarkingPeriod = importedCourseWork.filter((cw) => cw.checked).every((cw) => cw.schoolTermMarkingPeriodId);
            const allHaveCategory = importedCourseWork.filter((cw) => cw.checked).every((cw) => cw.gradeTemplateCategoryId);
            const allHaveDueDate = importedCourseWork.filter((cw) => cw.checked).every((cw) => cw.dueDate);
            return allHaveMaxPoints && allHaveMarkingPeriod && allHaveCategory && allHaveDueDate;
        },
    },
    watch: {
        googleImportData: {
            handler() {
                if (!this.googleImportData || !this.googleImportData.length) {
                    this.importedCourseWork = [];
                } else {
                    const { courseWork, gradeTemplate } = this;
                    const [imported] = this.googleImportData;
                    this.importedCourseWork = Object.values(imported).map((assignment) => {

                        const previousImport = courseWork.find((cw) => cw.courseWorkTitle == assignment.title && cw.isExternal) || null;

                        if (previousImport) {
                            return {
                                ...assignment,
                                checked: true,
                                schoolTermMarkingPeriodId: previousImport.schoolTermMarkingPeriodId || this.schoolTermMarkingPeriodId,
                                isExistingCourseWork: false,
                                courseWorkId: previousImport.courseWorkId || null,
                                gradeTemplateCategoryId: previousImport.gradeTemplateCategoryId || 0,
                                dueDate: moment(assignment.date).format('YYYY-MM-DD') || previousImport.dueDate || null,
                                maxPoints: parseInt(assignment.points) || parseInt(previousImport.maxPoints) || 100,
                                courseWork: previousImport || null,
                            };
                        }

                        const categories = gradeTemplate ? gradeTemplate.categories : [];
                        const possibleCategory = categories.find((c) => {
                            const { categoryName } = c;
                            if (['hw', 'homework'].includes(categoryName.toLowerCase())) {
                                return assignment.title.toLowerCase().includes(categoryName.toLowerCase()) || assignment.title.toLowerCase().includes('hw');
                            }

                            if (['quiz', 'test', 'exam', 'assessment', 'assessments'].includes(categoryName.toLowerCase())) {
                                return assignment.title.toLowerCase().includes(categoryName.toLowerCase()) || assignment.title.toLowerCase().includes('quiz')
                                    || assignment.title.toLowerCase().includes('test') || assignment.title.toLowerCase().includes('exam')
                                    || assignment.title.toLowerCase().includes('assessment') || assignment.title.toLowerCase().includes('assessments');
                            }

                            if (['project', 'classwork', 'assignment'].includes(categoryName.toLowerCase())) {
                                return assignment.title.toLowerCase().includes(categoryName.toLowerCase()) || assignment.title.toLowerCase().includes('project')
                                    || assignment.title.toLowerCase().includes('classwork') || assignment.title.toLowerCase().includes('assignment');
                            }

                            return assignment.title.toLowerCase().includes(categoryName.toLowerCase());
                        }) || null;

                        return {
                            ...assignment,
                            checked: true,
                            schoolTermMarkingPeriodId: this.schoolTermMarkingPeriodId,
                            isExistingCourseWork: false,
                            courseWorkId: null,
                            gradeTemplateCategoryId: possibleCategory ? possibleCategory.gradeTemplateCategoryId : 0,
                            dueDate: assignment.date ? moment(assignment.date).format('YYYY-MM-DD') : null,
                            maxPoints: parseInt(assignment.points) || 100,
                            courseWork: null,
                        };
                    });
                }
            },
            immediate: true,
        },
    },
    methods: {
        importCourseWork() {
            const v = this;
            if (v.saving) return;

            const { canImport, importedCourseWork } = v;
            if (!canImport) {
                v.invalidImport = true;
                return;
            }
            v.invalidImport = false;
            v.saving = true;

            const { googleImportData, gradebookSource } = v;
            const { $store, course, gradeTemplate } = v;
            const { courseSectionId } = course;

            async.auto({
                newCourseWork(next) {
                    const courseWorks = importedCourseWork.filter((cw) => cw.checked && !cw.courseWorkId).map((cw) => ({
                        schoolTermMarkingPeriodId: cw.schoolTermMarkingPeriodId,
                        teacherExtCourseWorkId: null,
                        courseSectionId,
                        courseWorkTitle: cw.title,
                        assigneeMode: 'ALL_STUDENTS',
                        courseWorkDescription: null,
                        gradeTemplateCategoryId: cw.gradeTemplateCategoryId,
                        maxPoints: cw.maxPoints,
                        allowExtraCredit: false,
                        courseWorkWeight: 1,
                        hideMarks: false,
                        excluded: false,
                        publishDate: null,
                        publishTime: null,
                        dueDate: moment(cw.dueDate).utc().format('YYYY-MM-DD'),
                        dueTime: '12:00:00',
                        linkingGuid: uuidv4(),
                        isExternal: true,
                    }))


                    if (!courseWorks.length) return next(null, []);
                    $store.dispatch(Types.actions.ADD_LOCAL_COURSE_WORK, {
                        courseWorks,
                        gradeTemplate,
                        callback(err, res) {
                            if (err) return next(err);
                            return next(null, res.courseWork);
                        },
                    });
                },
                updateCourseWork(next) {
                    const courseWorks = importedCourseWork.filter((cw) => cw.checked && cw.courseWorkId && cw.courseWork).map((cw) => ({
                        courseWorkId: cw.courseWorkId,
                        schoolTermMarkingPeriodId: cw.schoolTermMarkingPeriodId,
                        teacherExtCourseWorkId: cw.courseWork.teacherExtCourseWorkId || null,
                        courseSectionId,
                        courseWorkTitle: cw.title,
                        assigneeMode: cw.courseWork.assigneeMode,
                        courseWorkDescription: cw.courseWork.courseWorkDescription || null,
                        gradeTemplateCategoryId: cw.gradeTemplateCategoryId,
                        maxPoints: cw.maxPoints,
                        allowExtraCredit: cw.courseWork.allowExtraCredit || false,
                        courseWorkWeight: cw.courseWork.courseWorkWeight || 1,
                        hideMarks: cw.courseWork.hideMarks || false,
                        excluded: cw.courseWork.excluded || false,
                        publishDate: null,
                        publishTime: null,
                        dueDate: moment(cw.dueDate).utc().format('YYYY-MM-DD'),
                        dueTime: '12:00:00',
                        isExternal: true,
                        deleted: false,
                    }))

                    if (!courseWorks.length) return next(null, []);
                    $store.dispatch(Types.actions.EDIT_LOCAL_COURSE_WORK, {
                        courseWorks,
                        gradeTemplate,
                        callback(err, res) {
                            if (err) return next(err);
                            return next(null, res.courseWork);
                        },
                    });
                },
            }, 5, (err, results) => {
                v.saving = false;
                if (err) return v.showError(err);

                const { newCourseWork, updateCourseWork } = results;
                const output = googleImportData.slice(1).map((row) => {
                    const record = { ...row };

                    const student = record.student;
                    record.student = {
                        courseSection: course,
                        courseSectionId,
                        courseSectionStudentId: student.courseSectionStudentId,
                        student,
                        studentEnrollmentId: student.studentEnrollmentId,
                        name: `${student.lastName}, ${student.firstName}`,
                    }

                    const gradebookSourceRow = gradebookSource.find((r) => r.student && r.student.studentEnrollmentId == record.student.studentEnrollmentId) || null;
                    if (!gradebookSourceRow) return null;

                    Object.entries(record).forEach(([key, row]) => {
                        if (key === 'student' || key === 'average') return;

                        const value = row.value || '';
                        const newCW = newCourseWork.find((cw) => cw.courseWorkTitle == key) || null;
                        if (newCW) {
                            record[`cw.${newCW.linkingGuid}`] = isNaN(value) ? value : value.length > 3 ? parseInt(value) || '' : parseFloat(value) || '';
                            delete record[key];
                            return;
                        }

                        const imported = importedCourseWork.find((cw) => cw.title == key && cw.index == row.index) || null;
                        if (!imported) return;

                        const updatedCourseWork = updateCourseWork.find((cw) => cw.courseWorkId == imported.courseWorkId) || null;
                        if (updatedCourseWork) {
                            record[`cw.${updatedCourseWork.linkingGuid}`] = isNaN(value) ? value : value.length > 3 ? parseInt(value) || '' : parseFloat(value) || '';
                            delete record[key];
                            return;
                        }
                    });

                    Object.entries(gradebookSourceRow).forEach(([key, value]) => {
                        if (!record.hasOwnProperty(key)) {
                            record[key] = value;
                        }
                    });

                    delete record.email;
                    delete record.firstName;
                    delete record.lastName;
                    return record;
                }).filter((r) => r);

                gradebookSource.forEach((row) => { // Add any students missing in upload that are enrolled in course on SyncGrades
                    const student = output.find((r) => r.student.studentEnrollmentId == row.student.studentEnrollmentId) || null;
                    if (student) return;

                    const record = { ...row };

                    newCourseWork.forEach((cw) => {
                        record[`cw.${cw.linkingGuid}`] = '';
                    });

                    updateCourseWork.forEach((cw) => {
                        record[`cw.${cw.linkingGuid}`] = row[`cw.${cw.linkingGuid}`] || '';
                    });

                    output.push(record);
                });

                output.sort((a, b) => {
                    if (a.student.name < b.student.name) return -1;
                    if (a.student.name > b.student.name) return 1;
                    return 0;
                });

                v.setImportSource(output);
                v.setImportingGrades(true);
                v.toggleShowGoogleImport();
                v.toggleShowImportExport();
            });
        },
        existingCourseWorkToggled(e, index) {
            this.importedCourseWork[index].isExistingCourseWork = e.target.checked;
            if (!e.target.checked) {
                this.importedCourseWork[index].courseWorkId = null;
                this.importedCourseWork[index].courseWork = null;
            }
        },
        existingCourseWorkSelected(e, index) {
            const { importedCourseWork, filteredCourseWork } = this;
            const courseWorkId = e.target.value;

            const foundIdx = importedCourseWork.findIndex((imported, idx) => imported.courseWorkId == courseWorkId && index != idx);
            if (foundIdx > -1) {
                this.importedCourseWork[foundIdx].courseWorkId = null;
                this.importedCourseWork[foundIdx].courseWork = null;
                this.importedCourseWork[foundIdx].gradeTemplateCategoryId = 0;
            }

            const cw = filteredCourseWork.find((cw) => cw.courseWorkId == courseWorkId) || null;
            this.importedCourseWork[index].courseWork = cw ? {...cw} : null;
            this.importedCourseWork[index].maxPoints = cw ? cw.maxPoints : this.importedCourseWork[index].maxPoints || 100;
            this.importedCourseWork[index].gradeTemplateCategoryId = cw ? cw.gradeTemplateCategoryId : this.importedCourseWork[index].gradeTemplateCategoryId || 0;
        },
        selectAll() {
            this.importedCourseWork = this.importedCourseWork.map((assignment) => ({
                ...assignment,
                checked: true,
            }));
        },
        selectNone() {
            this.importedCourseWork = this.importedCourseWork.map((assignment) => ({
                ...assignment,
                checked: false,
            }));
        },
        selectInvert() {
            this.importedCourseWork = this.importedCourseWork.map((assignment) => ({
                ...assignment,
                checked: !assignment.checked,
            }));
        },
    },
};
</script>

<style>
.modal-sm {
    max-width: 75vw !important;
    width: 75vw !important;
}
</style>
