<template>
<div
    v-if="show"
    class="modal modal-sticky-bottom-right modal-sticky-lg show d-block"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    :style="{ bottom: floatingExportImportOpen ? '330px' : ''}"
    style="overflow: visible; max-width: 650px;"
>
    <div class="modal-dialog" style="max-width: 650px;">
        <div class="modal-content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Gradebook Sorting Options
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-actions">
                            <a
                                href="#"
                                class="btn btn-icon"
                                @click.stop.prevent="toggleShowSortOptions"
                            >
                                <i class="la la-close" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-section">
                        <div class="kt-section__content">
                            <div class="row align-items-center ml-2">
                                <span class="mr-3">
                                    Sort By
                                </span>
                                <div class="dropdown show">
                                    <button
                                        id="dropdownMenuButton"
                                        class="btn btn-outline-primary dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                    >
                                        <span :style="{paddingLeft: !sortBy ? '100px' : ''}">
                                            {{ sortBy }}
                                        </span>
                                    </button>
                                    <div
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                        x-placement="bottom-start"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 36px, 0px);"
                                    >
                                        <span
                                            v-for="(option, idx) in sortOptions.map((s) => s.sortBy)"
                                            :key="`options_${option}_${idx}`"
                                            class="dropdown-item"
                                            style="cursor: pointer"
                                            @click="setSortBy(option)"
                                        >{{ option }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="sortBy" class="kt-section__content kt-section__content--solid mt-3">
                            <button
                                v-for="(direction, idx) in (sortOptions.find((s) => s.sortBy === sortBy) || {}).sortDirections || []"
                                :key="`sortDirection_${direction}_${idx}`"
                                type="button"
                                :class="`btn mr-2 ${direction === sortDirection ? `btn-${sortDirectionColors[direction]}` : `btn-label-${sortDirectionColors[direction]}`}`"
                                @click.stop.prevent="setSortDirection(direction)"
                            >
                                {{ direction }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import studentMixins from '../store/mixins/studentMixins';
import panelMixins from '../store/mixins/panelMixins';

export default {
    name: 'TheGradebookFloatingSortOptions',
    components: {
    },
    mixins: [
        studentMixins,
        panelMixins,
    ],
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        toggleShowSortOptions: {
            type: Function,
            required: true,
        },
        sortBy: {
            type: String,
            required: false,
            default: null,
        },
        sortDirection: {
            type: String,
            required: false,
            default: null,
        },
        setSortBy: {
            type: Function,
            required: true,
        },
        setSortDirection: {
            type: Function,
            required: true,
        },
        floatingExportImportOpen: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            sortOptions: [
                {
                    sortBy: 'Coursework',
                    sortDirections: ['A-Z Ascending', 'A-Z Descending', 'Points Ascending', 'Points Descending'],
                },
                {
                    sortBy: 'Coursework Category',
                    sortDirections: ['A-Z Ascending', 'A-Z Descending', 'Points Ascending', 'Points Descending'],
                },
                {
                    sortBy: 'Due Date',
                    sortDirections: ['Ascending', 'Descending'],
                },
                {
                    sortBy: 'Publish Date',
                    sortDirections: ['Ascending', 'Descending'],
                },
            ],
            sortDirectionColors: {
                'A-Z Ascending': 'primary',
                'A-Z Descending': 'success',
                'Points Ascending': 'warning',
                'Points Descending': 'danger',
                Ascending: 'primary',
                Descending: 'success',
            },
        };
    },
    computed: {
        large() {
            const { deviceType } = this.$store.state;
            return ['desktop', 'laptop', 'tablet'].includes(deviceType);
        },
        tab() {
            return this.tabs.find((t) => t.active);
        },
    },
    methods: {
    },
};

</script>
