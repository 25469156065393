var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPortlet
    ? _c(
        "div",
        {
          ref: "draggableContainer",
          staticClass: "modal-sticky-md modal show d-block",
          style: {
            bottom:
              _vm.floatingSortOpen || _vm.floatingExportImportOpen
                ? `${
                    (_vm.floatingSortOpen ? 210 : 0) +
                    (_vm.floatingExportImportOpen ? 320 : 0)
                  }px`
                : "",
          },
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c("div", { staticClass: "kt-portlet mb-0" }, [
            _c(
              "div",
              {
                staticClass: "kt-portlet__head pr-2",
                on: { mousedown: _vm.dragMouseDown },
              },
              [
                _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "nav nav-pills nav-pills-sm",
                      attrs: { role: "tablist" },
                    },
                    _vm._l(_vm.tabs, function (t) {
                      return _c(
                        "li",
                        { key: t.name, staticClass: "nav-item" },
                        [
                          _c(
                            "a",
                            {
                              class: `nav-link ${t.active ? "active" : ""}`,
                              attrs: {
                                href: "#",
                                "data-toggle": "tab",
                                role: "tab",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.activateTab(t)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(t.name) + " ")]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "kt-portlet__head-label float-right mr-0" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-icon",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.toggleShowFloatingPortlet.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "la la-close" })]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "kt-portlet__body",
                class: { "p-0": _vm.tab.name == "Averages" },
              },
              [
                _vm.tab.name == "Mark"
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-8" }, [
                        _c(
                          "div",
                          {
                            staticClass: "media",
                            style: {
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            },
                          },
                          [
                            _c(
                              "UserAvatar",
                              {
                                staticClass: "kt-widget7__img mx-3 my-0",
                                staticStyle: { "max-height": "38px" },
                                attrs: { "avatar-user": _vm.student },
                              },
                              [
                                _c("div", { staticClass: "kt-media" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold",
                                    },
                                    [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { class: "media-body" }, [
                              _c(
                                "div",
                                { staticClass: "text-nowrap text-truncate" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "kt-widget5__title pb-0 w-100",
                                      attrs: {
                                        event: "",
                                        to: {
                                          name: "StudentCourseOverview",
                                          params: {
                                            studentEnrollmentId:
                                              _vm.studentEnrollmentId,
                                            extCourseSectionId:
                                              _vm.course.extCourseSectionId,
                                          },
                                        },
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPanelForStudent(
                                            _vm.student
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.student.lastName) +
                                          ", " +
                                          _vm._s(_vm.student.firstName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      class: `kt-font kt-font-transform-u ml-3 ${_vm.courseWork.color} `,
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.courseWork.categoryName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-nowrap text-truncate mt-1",
                                },
                                [
                                  _vm.courseWork.courseWorkDomain == "Local"
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass: "kt-link kt-font-dark",
                                          attrs: {
                                            event: "",
                                            to: {
                                              name: "TeacherLocalCourseAssignmentEdit",
                                              params: {
                                                courseWorkId:
                                                  _vm.courseWork.courseWorkId,
                                                extCourseSectionId:
                                                  _vm.course.extCourseSectionId,
                                                schoolEmail:
                                                  _vm.teacher.schoolEmail,
                                              },
                                            },
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.openPanelForCourseWork(
                                                _vm.courseWork
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.courseWork.courseWorkTitle
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.courseWork.courseWorkDomain == "Google" &&
                                  _vm.courseWork.alternateLink
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "kt-link kt-font-dark",
                                          attrs: {
                                            target: "_blank",
                                            href: _vm.courseWork.alternateLink,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.courseWork.courseWorkTitle
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm.courseWorkGrade
                        ? _c("div", { staticClass: "col-2" }, [
                            _c("div", { staticClass: "text-center" }, [
                              _c("div", [_vm._v("Mark")]),
                              _vm.courseWorkGrade.deleted
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-font-bold kt-font-danger",
                                    },
                                    [_vm._v(" Deleted ")]
                                  )
                                : _vm.courseWorkGrade.mark === ""
                                ? _c("div", { staticClass: "kt-font-bold" }, [
                                    _vm._v(" No Grade "),
                                  ])
                                : _c("div", { staticClass: "kt-font-bold" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.courseWorkGrade.mark) +
                                        " "
                                    ),
                                  ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "text-center" }, [
                          _c("div", [_vm._v("Points")]),
                          _c("div", { staticClass: "kt-font-bold" }, [
                            _vm._v(
                              " " + _vm._s(_vm.courseWork.maxPoints) + " "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm.courseWorkGrade && _vm.courseWorkGrade.comment
                        ? _c("div", { staticClass: "mx-3 mt-3 col-12" }, [
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(_vm._s(_vm.courseWorkGrade.comment)),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.tab.name == "Averages"
                  ? _c("StudentMarkingPeriodAveragePortlet")
                  : _vm._e(),
                _vm.tab.name == "Audit" ? _c("GradeAuditPortlet") : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }