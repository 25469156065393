<template>
<div>
    <div v-if="!change">
        <span class="text-muted">There are no changes to audit.</span>
    </div>
    <div
        v-else
        :key="`change_${change.pageNumber}`"
        class="row pb-4"
    >
        <div class="col-4">
            <div class="">
                <div>When</div>
                <div class="kt-font-bold">
                    {{ change.relativeDate }}
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="">
                <div>Author</div>
                <div class="kt-font-bold">
                    {{ change.author }}
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="text-center">
                <div>Mark</div>
                <div
                    class="kt-font-bold"
                    :class="change.deleted ? 'kt-font-danger' : ''"
                >
                    {{ change.mark }}
                </div>
            </div>
        </div>
        <div
            v-if="change.comment"
            class="col-12 pt-2 pb-0 mb-0"
        >
            <span class="text-muted">
                {{ change.comment }}
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="text-muted">
                <span v-if="change">
                    {{ change.timestamp }}
                </span>
            </div>
        </div>
        <div class="col-6">
            <div class="pull-right kt-pagination kt-pagination--info kt-pagination--sm">
                <ul class="kt-pagination__links">
                    <li :class="`kt-pagination__link--next ${selectedPageIndex == 0 ? 'kt-pagination__link--disabled' : ''}`">
                        <a
                            href="#"
                            @click.stop.prevent="moveFirst"
                        ><i class="fa fa-angle-left kt-font-info" /></a>
                    </li>
                    <li
                        v-for="item in visibleChanges"
                        :key="`item_${item.pageNumber}`"
                        :class="item.active ? 'kt-pagination__link--active': ''"
                    >
                        <a
                            href="#"
                            @click.stop.prevent="selectedPageIndex = item.index"
                        >
                            {{ item.pageNumber }}
                        </a>
                    </li>
                    <li :class="`kt-pagination__link--prev ${selectedPageIndex == gradeChanges.length-1 ? 'kt-pagination__link--disabled' : ''}`">
                        <a
                            href="#"
                            @click.stop.prevent="moveLast"
                        >
                            <i class="fa fa-angle-right kt-font-info" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import moment from 'moment';
import studentMixins from '../store/mixins/studentMixins';
import courseMixins from '../store/mixins/courseMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import { getGradeForStudent } from '../store/mixins/courseWorkGradeMixins';

export default {
    name: 'GradeAuditPortlet',
    mixins: [
        studentMixins,
        courseMixins,
        teacherMixins,
    ],
    data() {
        return {
            selectedPageIndex: 0,
        };
    },
    computed: {
        student() {
            if (!this.studentEnrollmentId) return null;
            return this.$_studentMixins_getStudentInCourse(this.studentEnrollmentId, this.courseSectionId);
        },
        initials() {
            if (!this.student) return '';
            return this.student.lastName.substring(0, 2);
        },
        markingPeriodAverage() {
            const { schoolTermMarkingPeriodId, student } = this;
            if (!student) return null;
            return (student.markingPeriodAverages || []).find((a) => a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        averageCalculation() {
            const { markingPeriodAverage } = this;
            if (!markingPeriodAverage) return null;
            const { markingPeriod } = markingPeriodAverage.averageCalculation;
            return markingPeriod || [];
        },
        selectedGradingCategory() {
            if (!this.courseWork) return null;
            return this.courseWork.categoryName;
        },
        category() {
            const { selectedGradingCategory } = this;
            if (!selectedGradingCategory) return null;
            return this.categories.find((cat) => cat.categoryName == selectedGradingCategory);
        },
        categories() {
            const { averageCalculation } = this;
            if (!averageCalculation) return [];
            return (averageCalculation.categories || []).filter((c) => c && c.scaled);
        },
        activeCell() {
            return this.$store.state.database.gradebook.activeCell;
        },
        linkingGuid() {
            return this.activeCell.linkingGuid;
        },
        courseWorkDomain() {
            return this.activeCell.courseWorkDomain;
        },
        studentEnrollmentId() {
            return this.activeCell.studentEnrollmentId;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        courseSectionId() {
            if (!this.course) return null;
            return this.course.courseSectionId;
        },
        courseWork() {
            const { linkingGuid, courseWorkDomain } = this;
            if (!this.course) return null;
            const { courseSectionId } = this.course;
            if (courseWorkDomain == 'Local') {
                return this.$store.state.database.courseWork.find((cw) => cw.linkingGuid == linkingGuid && cw.courseSectionId == courseSectionId) || null;
            }
            if (courseWorkDomain == 'Google') {
                return this.$store.state.database.googleCourseWork.find((cw) => cw.linkingGuid == linkingGuid && cw.courseSectionId == courseSectionId) || null;
            }
            return null;
        },
        courseWorkGrade() {
            const { student, courseWork } = this;
            if (!courseWork || !student) return null;
            return getGradeForStudent(this.$store.state.database, courseWork, student);
        },
        change() {
            const { selectedPageIndex, gradeChanges } = this;
            return gradeChanges[selectedPageIndex];
        },
        visiblePageNumbers() {
            const { courseWorkGrade, selectedPageIndex } = this;
            if (!courseWorkGrade) return [];
            const totalPages = courseWorkGrade.change.history.length;
            const pageNumber = selectedPageIndex + 1;
            let visiblePageNumbers = [1, 2, 3];
            if (pageNumber >= 2) {
                visiblePageNumbers = [pageNumber - 1, pageNumber];
                if (pageNumber + 1 <= totalPages) {
                    visiblePageNumbers.push(pageNumber + 1);
                }

                if (visiblePageNumbers.length == 2) {
                    visiblePageNumbers.push(pageNumber - 2);
                }
            }
            return visiblePageNumbers;
        },
        gradeChanges() {
            const { courseWorkGrade, visiblePageNumbers, selectedPageIndex } = this;
            if (!courseWorkGrade) return [];
            const history = courseWorkGrade.change.history.slice().toReversed();

            return history.map((c, index) => {
                const change = { ...c };
                change.index = index;
                change.pageNumber = index + 1;
                change.active = index == selectedPageIndex;
                change.visible = visiblePageNumbers.includes(change.pageNumber);
                const date = moment.utc(change.created).local();
                change.timestamp = date.format('ddd MMM D YYYY h:mm a');
                change.relativeDate = date.fromNow();
                return change;
            });
        },
        visibleChanges() {
            return this.gradeChanges.filter((c) => c.visible);
        },
    },
    methods: {
        moveFirst() {
            this.selectedPageIndex = 0;
        },
        moveLast() {
            this.selectedPageIndex = this.gradeChanges.length - 1;
        },
    },
};

</script>

<style lang="scss" scoped>
.modal-sticky-md {
    position: absolute !important;
    height: fit-content !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px  !important;
}
</style>
