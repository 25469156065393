<template>
<div ref="draggableContainer" class="draggable-container">
    <div
        v-if="show"
        class="modal modal-sticky-bottom-right modal-sticky-lg show d-block"
        role="dialog"
        data-backdrop="false"
        aria-modal="true"
        style="overflow: visible; position: relative;"
        :style="{ maxWidth: googleCourse ? '650px' : '950px', width: googleCourse ? '650px' : '950px' }"
    >
        <div class="modal-dialog" :style="{ maxWidth: googleCourse ? '650px' : '950px', width: googleCourse ? '650px' : '950px' }">
            <div class="modal-content">
                <div class="kt-portlet kt-portlet--mobile">
                    <div class="kt-portlet__head" @mousedown="dragMouseDown">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Gradebook Export / Import
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-portlet__head-actions">
                                <a
                                    v-if="!importingGrades"
                                    href="#"
                                    class="btn btn-icon"
                                    @click.stop.prevent="toggleShowImportExport"
                                >
                                    <i class="la la-close" />
                                </a>
                                <a
                                    v-else
                                    href="#"
                                    class="btn btn-sm btn-icon btn-clean btn-icon-md"
                                    @click.stop.prevent="toggleSize"
                                >
                                    <i :class="['la', minimized ? 'la-angle-up' : 'la-angle-down' ]" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body" :class="[minimized ? 'd-none' : '']">
                        <div v-if="!importingGrades" class="row row-no-padding row-col-separator-xl">
                            <div class="px-4" :class="{'col-xl-6': googleCourse, 'col-xl-4': !googleCourse}">
                                <div class="row align-items-center w-100 pl-2">
                                    <span class="kt-font-lg">
                                        Export Data
                                    </span>
                                    <button
                                        class="btn btn-clean btn-icon ml-auto"
                                        type="button"
                                        :disabled="!hasGrades"
                                        @click.stop.prevent="exportData"
                                    >
                                        <i class="flaticon-download-1" />
                                    </button>
                                </div>
                                <div>
                                    <small>
                                        This download is required to be used as a template for importing data
                                    </small>
                                </div>
                            </div>
                            <div class="px-4" :class="{'col-xl-6': googleCourse, 'col-xl-4': !googleCourse}">
                                <div class="row align-items-center w-100 pl-2">
                                    <span class="kt-font-lg">
                                        Import Data
                                    </span>
                                    <input
                                        id="file-input"
                                        type="file"
                                        name="file"
                                        style="display: none"
                                        accept=".csv"
                                        @change="onFileBrowseSelect($event, false)"
                                    >
                                    <button
                                        class="btn btn-clean btn-icon ml-auto"
                                        type="button"
                                        :disabled="!canEditGradebook || !hasGrades"
                                        @click.stop.prevent="triggerFileBrowser(false)"
                                    >
                                        <i class="flaticon-upload" />
                                    </button>
                                </div>
                                <div>
                                    <small>
                                        Import the gradebook Export CSV with changes to update grades
                                    </small>
                                </div>
                            </div>
                            <div v-if="!googleCourse" class="col-xl-4 px-4">
                                <div class="row align-items-center w-100 pl-2">
                                    <span class="kt-font-lg kt-font-google kt-font-bolder">
                                        Google Import
                                    </span>
                                    <input
                                        id="google-file-input"
                                        type="file"
                                        name="file"
                                        style="display: none"
                                        accept=".csv"
                                        @change="onFileBrowseSelect($event, true)"
                                    >
                                    <button
                                        class="btn btn-clean-google-custom btn-icon ml-auto"
                                        type="button"
                                        :disabled="!canEditGradebook"
                                        @click.stop.prevent="triggerFileBrowser(true)"
                                    >
                                        <i class="flaticon-upload" />
                                    </button>
                                </div>
                                <div>
                                    <small>
                                        Import the Google Classroom CSV to update grades and assignments
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div v-else class="kt-section">
                            <div class="kt-section__content">
                                <div class="row align-items-center ml-2">
                                    <span class="mr-3">
                                        The Gradebook has been repopulated with your imported data to review. Revise the changes, edit any invalid grades, and select Save Grades to complete import.
                                    </span>
                                </div>
                            </div>
                            <div class="kt-section__content kt-section__content--solid mt-3">
                                <button
                                    type="button"
                                    class="btn btn-label-danger mr-2"
                                    :disabled="saving"
                                    @click.stop.prevent="cancelImport"
                                >
                                    Cancel Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-success mr-2"
                                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                    :disabled="saving"
                                    @click.stop.prevent="saveImportedData"
                                >
                                    Save Grades
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import studentMixins from '../store/mixins/studentMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';

export default {
    name: 'TheGradebookFloatingExportImport',
    components: {
    },
    mixins: [
        studentMixins,
        googleCourseMixins,
    ],
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        toggleShowImportExport: {
            type: Function,
            required: true,
        },
        exportData: {
            type: Function,
            required: true,
        },
        importData: {
            type: Function,
            required: true,
        },
        importGoogleData: {
            type: Function,
            required: true,
        },
        saveImportedData: {
            type: Function,
            required: true,
        },
        cancelImport: {
            type: Function,
            required: true,
        },
        importingGrades: {
            type: Boolean,
            required: true,
        },
        saving: {
            type: Boolean,
            required: true,
        },
        canEditGradebook: {
            type: Boolean,
            required: true,
        },
        hasGrades: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            minimized: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
            },
        };
    },
    computed: {
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
    },
    methods: {
        triggerFileBrowser(isGoogle) {
            const { canEditGradebook } = this;
            if (!canEditGradebook) return;
            if (isGoogle) return document.getElementById('google-file-input').click();
            document.getElementById('file-input').click();
        },
        onFileBrowseSelect(e, isGoogle) {
            const file = e.target.files[0];
            if (isGoogle) return this.importGoogleData(file);
            return this.importData(file);
        },
        toggleSize() {
            this.minimized = !this.minimized;

            if (this.minimized) {
                this.positions.movementX = undefined;
                this.positions.movementY = undefined;
                this.positions.clientX = 0;
                this.positions.clientY = 0;
                this.$refs.draggableContainer.style.top = 'auto';
                this.$refs.draggableContainer.style.left = 'auto';
            }
        },
        dragMouseDown(event) {
            if (!this.importingGrades) return;
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY}px`;
            this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX}px`;
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
    },
};
</script>

<style scoped lang="scss">
.draggable-container {
    z-index: 100;
    position: fixed;
    left: auto;
    bottom: 5px;
    top: auto;
    right: 5px;
}

.btn.btn-clean-google-custom {
	border-color: transparent;
	background: transparent;
	color: #dc4e41;

	i {
		color: #dc4e41;
	}

	.show > &,
	&.active,
	&:active,
	&:hover {
		color: #dc4e41;
		background: #fce3e1;
		i {
			color:#dc4e41;
		}
	}
}
</style>
