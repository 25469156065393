<template>
<div class="kt-widget15">
    <div class="kt-widget15__items">
        <div class="row">
            <div class="col">
                <div class="kt-widget15__item mt-2">
                    <div class="w-100 text-nowrap text-truncate">
                        <span class="kt-widget15__stats1">
                            <span :class="`mr-2 font kt-font-bold`">{{ percentage }}%</span>
                            <!-- <span :class="`font ${labelColor}`">{{ label }}</span> -->
                            <span :class="`kt-font kt-font-transform-u ml-3 ${labelColor} `">
                                {{ label }}
                            </span>
                        </span>
                    </div>
                    <div class="kt-space-5" />
                    <div class="progress kt-widget15__chart-progress--sm mb-0">
                        <div
                            :class="`progress-bar ${color}`"
                            role="progressbar"
                            :style="{width: `${percentage}%`}"
                            :aria-valuenow="percentage"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'StudentMarkingPeriodAverageTotals',
    props: {
        percentage: {
            type: Number,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        labelColor: {
            type: String,
            required: true,
        },
        averageDisplay: {
            type: String,
            required: true,
        },
    },
});
</script>
<style scoped>
    .kt-widget15__text {
        min-width: 40px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
