<template>
<div
    v-show="showCreateMessage"
    ref="messageContainer"
    class="grouped-modal"
>
    <div class="d-flex flex-row">
        <div
            v-if="showSuggestedRecipients"
            class="modal modal-sticky-bottom-right modal-sticky-lg show mx-1"
            role="dialog"
            data-backdrop="false"
            aria-modal="true"
            style="display: inline-block; overflow: visible; position: relative; max-width: 20vw; width: 20vw;"
        >
            <b-overlay
                :variant="'transparent'"
                center
                opacity="1"
                blur="10px"
                :show="encryptionEnabled"
                style="height: 100%;"
            >
                <div
                    class="modal-dialog"
                    role="document"
                    style="height: 100%;"
                >
                    <div class="modal-content" style="height: 100%;">
                        <div class="kt-portlet kt-portlet--mobile" style="height: 100%;">
                            <div class="kt-portlet__head">
                                <div class="kt-portlet__head-label">
                                    <h3 class="kt-portlet__head-title">
                                        <span class="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-md kt-font-bolder py-2">
                                            <i class="fa fa-lightbulb mr-2" />
                                            Suggested Recipients
                                        </span>
                                    </h3>
                                </div>
                            </div>
                            <div class="kt-portlet__body" style="height: 100%;">
                                <div class="kt-widget4 kt-scroll" style="height: 52vh; overflow-x: hidden; overflow-y: auto;">
                                    <div
                                        v-for="(recipient, idx) in suggestedRecipients"
                                        :key="`suggested_recipient_${idx}`"
                                        class="kt-widget4__item"
                                    >
                                        <div class="kt-widget4__pic kt-widget4__pic--pic">
                                            <UserAvatar :avatar-user="recipient">
                                                <div class="kt-badge kt-badge--lg kt-badge--success">
                                                    {{ recipient.name.substring(0, 1) }}
                                                </div>
                                            </UserAvatar>
                                        </div>

                                        <div class="kt-widget4__info">
                                            <a
                                                href="#"
                                                class="kt-widget4__username"
                                                @click.stop.prevent="recipientAdded(recipient)"
                                            >
                                                {{ recipient.name }}
                                            </a>
                                            <p class="kt-widget4__text">
                                                {{ recipient.subName }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
        <div
            id="kt_inbox_compose"
            class="modal modal-sticky-bottom-right modal-sticky-lg show"
            role="dialog"
            data-backdrop="false"
            aria-modal="true"
            style="display: block; overflow: visible; position: relative; width: 36vw;"
        >
            <b-overlay
                :variant="'transparent'"
                no-center
                opacity="1"
                blur="10px"
                :show="encryptionEnabled"
            >
                <template #overlay>
                    <div class="pull-right mr-2 mt-2 kt-font-lg">
                        <button
                            type="button"
                            class="btn btn-icon btn-clean"
                            @click.stop.prevent="closeModal"
                        >
                            <i class="flaticon2-cross" />
                        </button>
                    </div>
                    <div class="text-center pt-4 kt-font-lg">
                        PII is currently encrypted. Please unlock PII to send a message.
                    </div>
                    <div class="text-center pt-4">
                        <button
                            v-if="encryptionEnabled"
                            type="button"
                            style="min-width: 160px;"
                            class="btn btn-label-brand btn-bold btn-upper mt-4"
                            @click="decrypt()"
                        >
                            Unlock PII
                        </button>
                    </div>
                </template>
                <div class="modal-dialog" role="document">
                    <div class="modal-content kt-inbox">
                        <div id="kt_inbox_compose_form" class="kt-inbox__form">
                            <div class="kt-inbox__head" @mousedown="dragMouseDown">
                                <div class="kt-inbox__title">
                                    Compose Message
                                </div>
                                <div class="kt-inbox__actions">
                                    <button
                                        v-if="hasContent || files.length"
                                        v-b-tooltip.hover="'Discard message'"
                                        class="kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light"
                                        @click.stop.prevent="clearMessage"
                                    >
                                        <i class="flaticon2-rubbish-bin-delete-button" />
                                    </button>
                                    <button
                                        class="btn btn-sm btn-icon btn-clean btn-icon-md"
                                        @click.stop.prevent="toggleSize"
                                    >
                                        <i :class="['la', minimized ? 'la-angle-up' : 'la-angle-down' ]" />
                                    </button>
                                    <button
                                        type="button"
                                        class="kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light"
                                        @click.stop.prevent="closeModal"
                                    >
                                        <i class="flaticon2-cross" />
                                    </button>
                                </div>
                            </div>
                            <div
                                class="kt-inbox__body"
                                style="position: relative;"
                                :class="[minimized ? 'd-none' : '']"
                            >
                                <div class="kt-inbox__to">
                                    <div class="kt-inbox__wrapper">
                                        <div class="kt-inbox__field kt-inbox__field--to">
                                            <div class="kt-inbox__label">
                                                To:
                                            </div>
                                            <div class="kt-inbox__input">
                                                <span
                                                    v-for="(recipient, idx) in newMessage.recipients"
                                                    :id="`recipient_${idx}-popover`"
                                                    :key="`recipient_${idx}`"
                                                    class="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded mx-1"
                                                >
                                                    {{ recipient.name }}
                                                    <span class="ml-2" @click.stop.prevent="removeRecipient(recipient)">
                                                        <i class="la la-close" />
                                                    </span>
                                                </span>
                                                <SearchRecipients
                                                    :on-selected="recipientAdded"
                                                    :school-staff-id="schoolStaffId"
                                                    :allow-school-wide="false"
                                                    :allow-my-students="false"
                                                    :show-courses="false"
                                                    :show-home-rooms="false"
                                                    :show-grade-levels="false"
                                                    :show-teachers="schoolStaffId ? userPermissions && userPermissions.canMessageSchoolUsers : true"
                                                    :show-student-groups="false"
                                                    :show-students="Boolean(schoolStaffId) && userPermissions && userPermissions.canMessageHomeUsers"
                                                    :show-guardians="Boolean(schoolStaffId) && userPermissions && userPermissions.canMessageHomeUsers"
                                                    :show-styling="false"
                                                    :placeholder="'Search for recipients...'"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-inbox__subject ql-toolbar ql-snow">
                                    <input
                                        v-model="newMessage.subject"
                                        class="form-control"
                                        placeholder="Subject"
                                    >
                                </div>
                                <div
                                    id="kt_inbox_compose_editor"
                                    class="kt-inbox__editor ql-container ql-snow px-3 pt-3"
                                    style="height: 34vh"
                                >
                                    <textarea
                                        v-model="newMessage.body"
                                        class="w-100 h-100"
                                        :style="[files.length ? { paddingBottom: `${files.length * 40}px`} : {}, ]"
                                        placeholder="Type message..."
                                        style="border: none !important; outline: none !important; resize: none !important;"
                                    />
                                </div>
                                <div class="w-100 px-3" style="position: absolute; bottom: 5px;">
                                    <div
                                        v-for="(file, idx) in files"
                                        :key="`attachedFile_${idx}`"
                                        class="attached-file px-3 py-1 my-2 d-flex align-items-center"
                                    >
                                        <span class="kt-link kt-link--state kt-link--primary kt-font-bolder no-wrap">
                                            {{ file.name }}
                                        </span>
                                        <a
                                            href="#"
                                            class="kt-link kt-link--state kt-link--danger kt-font-bolder ml-auto"
                                            @click.stop.prevent="files = files.filter((f, i) => i !== idx)"
                                        >
                                            <i class="flaticon2-cross" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-inbox__foot" :class="[minimized ? 'd-none' : '']">
                                <div class="kt-inbox__primary">
                                    <button
                                        type="button"
                                        class="btn btn-brand btn-bold mr-3"
                                        :class="{ 'kt-spinner  kt-spinner--sm kt-spinner--light': saving }"
                                        :disabled="!canSend || saving"
                                        @click.stop.prevent="sendMessage"
                                    >
                                        Send
                                    </button>
                                    <span
                                        v-if="newMessage.smsEnabled && canToggleSMS"
                                        v-b-tooltip.hover="characterCount > 2400 ? 'Any text past 2400 characters will not be sent with SMS' : characterCount > 1200 ? 'SMS will be sent as 2 separate messages' : ''"
                                        :class="{'kt-font-warning kt-font-bolder': characterCount > 1200 && characterCount <= 2400, 'kt-font-danger kt-font-bolder': characterCount > 2400}"
                                    >
                                        {{ characterCount }} / 2400 characters for SMS
                                    </span>
                                </div>
                                <div class="kt-inbox__secondary">
                                    <button
                                        v-b-tooltip.hover="'Allow recipients to reply to message'"
                                        type="button"
                                        class="btn btn-bold btn-icon mx-1"
                                        :class="{ 'btn-outline-success': !newMessage.allowReplies, 'btn-success': newMessage.allowReplies }"
                                        :disabled="$_userMixins_isHomeUser"
                                        @click.stop.prevent="newMessage.allowReplies = !newMessage.allowReplies"
                                    >
                                        <i class="fa fa-reply" />
                                    </button>
                                    <span v-b-tooltip.hover="subscription ? subscription.emailQuota !== null && (subscription.emailUsage >= subscription.emailQuota) ? 'Your school\'s email limit has been exceeded' : 'Notify email of recipients' : 'Notify email of recipients'">
                                        <button
                                            v-if="canToggleEmail"
                                            type="button"
                                            class="btn btn-bold btn-icon mx-1"
                                            :class="{ 'btn-outline-success': !newMessage.notifyEmail, 'btn-success': newMessage.notifyEmail }"
                                            :disabled="subscription ? subscription.emailQuota !== null && (subscription.emailUsage >= subscription.emailQuota) : false"
                                            @click.stop.prevent="newMessage.notifyEmail = !newMessage.notifyEmail"
                                        >
                                            <i class="flaticon2-new-email" />
                                        </button>
                                    </span>
                                    <span v-b-tooltip.hover="subscription ? subscription.smsQuota !== null && (subscription.smsUsage >= subscription.smsQuota) ? 'Your school\'s SMS limit has been exceeded' : 'Send SMS to recipients' : 'Send SMS to recipients'">
                                        <button
                                            v-if="canToggleSMS"
                                            type="button"
                                            class="btn btn-bold btn-icon mx-1"
                                            :class="{ 'btn-outline-warning': !newMessage.smsEnabled, 'btn-warning': newMessage.smsEnabled }"
                                            :disabled="subscription ? subscription.smsQuota !== null && (subscription.smsUsage >= subscription.smsQuota) : false"
                                            @click.stop.prevent="newMessage.smsEnabled = !newMessage.smsEnabled"
                                        >
                                            <i class="fa fa-mobile-alt" />
                                        </button>
                                    </span>
                                    <div>
                                        <input
                                            id="file-input"
                                            type="file"
                                            name="file"
                                            style="display: none"
                                            @change="onFileBrowseSelect"
                                        >
                                        <button
                                            v-b-tooltip.hover="'Attach up to 3 files to message'"
                                            type="button"
                                            class="btn btn-bold btn-icon btn-clean btn-clean-dark mx-1"
                                            :disabled="files.length >= 3"
                                            @click.stop.prevent="triggerFileBrowser"
                                        >
                                            <i class="fa fa-paperclip" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import async from 'async';
import UserAvatar from '../UserAvatar.vue';
import Types from '../../store/Types';
import userMixins from '../../store/mixins/userMixins';
import studentGuardianMixins from '../../store/mixins/studentGuardianMixins';
import SearchRecipients from '../SearchRecipients.vue';
import * as network from '../../network';
import * as util from '../../lib/uploads/util';

export default {
    name: 'CreateMessage',
    components: {
        UserAvatar,
        SearchRecipients,
    },
    mixins: [userMixins, studentGuardianMixins],
    data() {
        return {
            key: 0,
            showSuggestedRecipients: false,
            files: [],
            newMessage: {
                subject: '',
                body: '',
                recipients: [],
                allowReplies: true,
                notifyEmail: true,
                smsEnabled: true,
                attachments: [],
            },
            saving: false,
            minimized: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            showCreateMessage: (state) => state.communication.createMessage,
            userPermissions: (state) => state.user.userPermissions,
        }),
        school() {
            const { user } = this;
            const { school } = user;
            const { schoolId } = school;

            return this.$store.state.database.schools.find((s) => s.schoolId == schoolId) || null;
        },
        subscription() {
            const { user, school } = this;
            if (!school || !user.school) return null;

            return school.subscriptions.find((s) => s.schoolYear == user.school.schoolYear) || null;
        },
        encryptionEnabled() {
            if (this.$_userMixins_isHomeUser) return false;
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        hasContent() {
            const { newMessage } = this;
            return Boolean(newMessage.subject || newMessage.body || newMessage.recipients.length);
        },
        canSend() {
            const { newMessage, encryptionEnabled } = this;
            if (encryptionEnabled) return false;
            return Boolean(newMessage.subject && newMessage.body && newMessage.recipients.length);
        },
        canToggleEmail() {
            const { userPermissions } = this;
            if (!userPermissions.canNotifyEmail) return false;
            return true;
        },
        canToggleSMS() {
            const { userPermissions, newMessage } = this;
            if (!userPermissions.canNotifySms) return false;

            const { recipients } = newMessage;
            return recipients.some((r) => r.studentGuardianId);
        },
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId || null;
        },
        prePopulatedStaff() {
            return this.$store.state.communication.createMessageStaff;
        },
        prePopulatedStudent() {
            return this.$store.state.communication.createMessageStudent;
        },
        prePopulatedGuardian() {
            return this.$store.state.communication.createMessageGuardian;
        },
        characterCount() {
            const { newMessage } = this;
            return newMessage.subject.length + newMessage.body.length;
        },
        guardians() {
            return this.$_studentGuardianMixins_getStudentGuardians();
        },
        suggestedRecipients() {
            const { guardians, newMessage } = this;
            const { recipients } = newMessage;
            if (!recipients.length) return [];

            const suggested = [];

            recipients.forEach((r) => {
                if (r.studentEnrollmentId && !r.studentGuardianId) {
                    const studentGuardians = guardians.filter((g) => g.studentEnrollmentId == r.studentEnrollmentId).map((g) => ({
                        ...g,
                        name: `${g.guardianLastName}, ${g.guardianFirstName}`,
                        subName: `Guardian of ${r.firstName} ${r.lastName}`,
                        role: 'Guardian',
                    })) || [];
                    suggested.push(...studentGuardians);
                }
            });

            const unique = new Map();
            suggested.forEach((s) => unique.set(`${s.studentGuardianId}`, s));
            return [...unique.values()];
        },
    },
    watch: {
        prePopulatedStaff: {
            handler() {
                const { prePopulatedStaff } = this;
                if (prePopulatedStaff) {
                    this.newMessage.recipients.push({...prePopulatedStaff, name: `${prePopulatedStaff.lastName}, ${prePopulatedStaff.firstName}`});
                }
            },
            immediate: true,
        },
        prePopulatedStudent: {
            handler() {
                const { prePopulatedStudent } = this;
                if (prePopulatedStudent) {
                    this.newMessage.recipients.push({...prePopulatedStudent, name: `${prePopulatedStudent.lastName}, ${prePopulatedStudent.firstName}`});
                }
            },
            immediate: true,
        },
        prePopulatedGuardian: {
            handler() {
                const { prePopulatedGuardian } = this;
                if (prePopulatedGuardian) {
                    this.newMessage.recipients.push({...prePopulatedGuardian, name: `${prePopulatedGuardian.guardianLastName}, ${prePopulatedGuardian.guardianFirstName}`});
                }
            },
            immediate: true,
        },
        suggestedRecipients: {
            handler() {
                const { suggestedRecipients } = this;
                if (suggestedRecipients.length) this.showSuggestedRecipients = true;
                else this.showSuggestedRecipients = false;
            },
            immediate: true,
        },
        canToggleEmail: {
            handler() {
                const { userPermissions } = this;
                if (!userPermissions.canNotifyEmail) this.newMessage.notifyEmail = false;
            },
            immediate: true,
        },
        canToggleSMS: {
            handler() {
                const { userPermissions } = this;
                if (!userPermissions.canNotifySms) this.newMessage.smsEnabled = false;
            },
            immediate: true,
        },
        subscription: {
            handler() {
                const { subscription } = this;
                if (subscription) {
                    const { emailQuota, emailUsage, smsQuota, smsUsage } = subscription;
                    if (emailQuota !== null && (emailUsage >= emailQuota)) {
                        this.newMessage.notifyEmail = false;
                    }
                    if (smsQuota !== null && (smsUsage >= smsQuota)) {
                        this.newMessage.smsEnabled = false;
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        closeModal() {
            this.clearMessage();
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
            this.minimized = false;
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_STAFF, null);
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_STUDENT, null);
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_GUARDIAN, null);
        },
        toggleSize() {
            this.minimized = !this.minimized;

            if (this.minimized) {
                this.showSuggestedRecipients = false;
                this.positions.movementX = undefined;
                this.positions.movementY = undefined;
                this.positions.clientX = 0;
                this.positions.clientY = 0;
                this.$refs.messageContainer.style.top = 'auto';
                this.$refs.messageContainer.style.left = 'auto';
            } else {
                if (this.suggestedRecipients.length) this.showSuggestedRecipients = true;
            }
        },
        dragMouseDown(event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            this.$refs.messageContainer.style.top = `${this.$refs.messageContainer.offsetTop - this.positions.movementY}px`;
            this.$refs.messageContainer.style.left = `${this.$refs.messageContainer.offsetLeft - this.positions.movementX}px`;
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        onFileBrowseSelect(e) {
            const { files } = this;
            const [file] = e.target.files;

            if (!file) return;
            if (files.length >= 3) return this.showError('You can not attach more than 3 files to a message');
            if (file.size > 52428800) return this.showError('File size must be less than 50MB');

            this.files.push(file);
            e.target.value = '';
        },
        sendMessage() {
            const v = this;
            if (v.saving || !v.canSend) return;
            v.saving = true;

            const { user, newMessage } = v;
            const { school } = user;
            const { schoolId, schoolTermId } = school;

            async.auto({
                uploadAttachments(next) {
                    async.eachLimit(v.files, 5, (file, nextFile) => {
                        util.uploadToAWS('message-attachments', file, user, (err, results) => {
                            if (err) return nextFile(err);
                            const { parameters } = results;
                            const { key } = parameters.fields;
                            if (!key) return nextFile(new Error('Attachment upload failed'));

                            newMessage.attachments.push({
                                fileName: file.name,
                                fileBucketPath: `https://lilo-app.s3.amazonaws.com/${key}`,
                            });
                            return nextFile(err, results);
                        });
                    }, next)
                },
                sendMessage: ['uploadAttachments', (results, next) => {
                    const params = {
                        url: {
                            schoolId,
                            schoolTermId,
                        },
                        body: {
                            message: { ...newMessage, subject: newMessage.subject.trim(), body: newMessage.body.trim() },
                        },
                    };

                    network.communication.createMessage(params, (err, res) => {
                        if (err) return next(err);
                        const { message, emailsQueued } = res;
                        return next(null, { message, emailsQueued });
                    });
                }],
            }, 5, (err, results) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { sendMessage } = results;
                const { message, emailsQueued } = sendMessage;
                if (message) {
                    v.showNotification(`Message sent ${emailsQueued ? 'and emails queued' : ''}`);
                    v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, messageRead: true, isOwner: true }]);
                    v.closeModal();
                }
            });
        },
        recipientAdded(recipient) {
            const { schoolStaffId, studentEnrollmentId, studentGuardianId } = recipient;

            const { recipients } = this.newMessage;
            let found = null;
            if (schoolStaffId) found = recipients.find((u) => u.schoolStaffId == schoolStaffId);
            else if (studentGuardianId) found = recipients.find((u) => u.studentGuardianId == studentGuardianId);
            else if (studentEnrollmentId) found = recipients.find((u) => u.studentEnrollmentId == studentEnrollmentId && u.role !== 'Guardian');

            if (!found) recipients.push(recipient);
        },
        removeRecipient(recipient) {
            const { recipients } = this.newMessage;
            const idx = recipients.findIndex((u) => {
                if (recipient.schoolStaffId) return u.schoolStaffId == recipient.schoolStaffId;
                else if (recipient.studentGuardianId) return u.studentGuardianId == recipient.studentGuardianId;
                else if (recipient.studentEnrollmentId) return u.studentEnrollmentId == recipient.studentEnrollmentId;
            });
            if (idx >= 0) recipients.splice(idx, 1);
        },
        clearMessage() {
            this.files = [];
            this.newMessage = {
                subject: '',
                body: '',
                recipients: [],
                allowReplies: true,
                notifyEmail: true,
                smsEnabled: true,
                attachments: [],
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/pages/inbox/inbox.scss";

.grouped-modal {
    z-index: 100;
    position: fixed;
    left: auto;
    bottom: 5px;
    top: auto;
    right: 5px;
}

.attached-file {
    box-shadow: 0px 0px 8px 0px #bababa;
    border-radius: 4px;
    background-color: white;
}
</style>
