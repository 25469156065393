<template>
<div
    v-if="showPortlet"
    ref="draggableContainer"
    class="modal-sticky-md modal show d-block"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
     :style="{ bottom: floatingSortOpen || floatingExportImportOpen ? `${(floatingSortOpen ? 210 : 0) + (floatingExportImportOpen ? 320 : 0)}px` : ''}"
>
    <div class="kt-portlet mb-0">
        <div
            class="kt-portlet__head pr-2"
            @mousedown="dragMouseDown"
        >
            <div class="kt-portlet__head-toolbar">
                <ul
                    class="nav nav-pills nav-pills-sm"
                    role="tablist"
                >
                    <li
                        v-for="t in tabs"
                        :key="t.name"
                        class="nav-item"
                    >
                        <a
                            :class="`nav-link ${t.active ? 'active' : ''}`"
                            href="#"
                            data-toggle="tab"
                            role="tab"
                            @click.stop.prevent="activateTab(t)"
                        >
                            {{ t.name }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="kt-portlet__head-label float-right mr-0">
                <a
                    href="#"
                    class="btn btn-icon"
                    @click.stop.prevent="toggleShowFloatingPortlet"
                >
                    <i class="la la-close" />
                </a>
            </div>
        </div>
        <div
            class="kt-portlet__body"
            :class="{ 'p-0': tab.name == 'Averages'}"
        >
            <div
                v-if="tab.name == 'Mark'"
                class="row"
            >
                <div class="col-8">
                    <div
                        class="media"
                        :style="{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }"
                    >
                        <UserAvatar
                            :avatar-user="student"
                            style="max-height: 38px;"
                            class="kt-widget7__img mx-3 my-0 "
                        >
                            <div class="kt-media">
                                <span class="kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold">
                                    {{ initials }}
                                </span>
                            </div>
                        </UserAvatar>
                        <div :class="'media-body'">
                            <div class="text-nowrap text-truncate">
                                <router-link
                                    class="kt-widget5__title pb-0 w-100"
                                    event=""
                                    :to="{
                                        name: 'StudentCourseOverview',
                                        params: {
                                            studentEnrollmentId,
                                            extCourseSectionId: course.extCourseSectionId
                                        },
                                    }"
                                    @click.native.prevent="openPanelForStudent(student)"
                                >
                                    {{ student.lastName }}, {{ student.firstName }}
                                </router-link>
                                <span :class="`kt-font kt-font-transform-u ml-3 ${courseWork.color} `">
                                    {{ courseWork.categoryName }}
                                </span>
                            </div>
                            <div class="text-nowrap text-truncate mt-1">
                                <router-link
                                    v-if="courseWork.courseWorkDomain == 'Local'"
                                    class="kt-link kt-font-dark"
                                    event=""
                                    :to="{
                                        name: 'TeacherLocalCourseAssignmentEdit',
                                        params: {
                                            courseWorkId: courseWork.courseWorkId,
                                            extCourseSectionId: course.extCourseSectionId,
                                            schoolEmail: teacher.schoolEmail,
                                        }
                                    }"
                                    @click.native.prevent="openPanelForCourseWork(courseWork)"
                                >
                                    {{ courseWork.courseWorkTitle }}
                                </router-link>
                                <a
                                    v-if="courseWork.courseWorkDomain == 'Google' && courseWork.alternateLink"
                                    class="kt-link kt-font-dark"
                                    target="_blank"
                                    :href="courseWork.alternateLink"
                                >
                                    {{ courseWork.courseWorkTitle }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="courseWorkGrade"
                    class="col-2"
                >
                    <div class="text-center">
                        <div>Mark</div>
                        <div
                            v-if="courseWorkGrade.deleted"
                            class="kt-font-bold kt-font-danger"
                        >
                            Deleted
                        </div>
                        <div
                            v-else-if="courseWorkGrade.mark === ''"
                            class="kt-font-bold"
                        >
                            No Grade
                        </div>
                        <div
                            v-else
                            class="kt-font-bold"
                        >
                            {{ courseWorkGrade.mark }}
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="text-center">
                        <div>Points</div>
                        <div class="kt-font-bold">
                            {{ courseWork.maxPoints }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="courseWorkGrade && courseWorkGrade.comment"
                    class="mx-3 mt-3 col-12"
                >
                    <span class="text-muted">{{ courseWorkGrade.comment }}</span>
                </div>
            </div>

            <StudentMarkingPeriodAveragePortlet v-if="tab.name == 'Averages'" />
            <GradeAuditPortlet v-if="tab.name == 'Audit'" />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import studentMixins from '../store/mixins/studentMixins';
import panelMixins from '../store/mixins/panelMixins';
import UserAvatar from './UserAvatar.vue';
import StudentMarkingPeriodAveragePortlet from './StudentMarkingPeriodAveragePortlet.vue';
import GradeAuditPortlet from './GradeAuditPortlet.vue';
import { getGradeForStudent } from '../store/mixins/courseWorkGradeMixins';

export default {
    name: 'TheGradebookFloatingPortlet',
    components: {
        UserAvatar,
        StudentMarkingPeriodAveragePortlet,
        GradeAuditPortlet,
    },
    mixins: [
        studentMixins,
        panelMixins,
    ],
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        },
        teacher: {
            type: Object,
            required: true,
        },
        toggleShowFloatingPortlet: {
            type: Function,
            required: true,
        },
        floatingSortOpen: {
            type: Boolean,
            required: true,
        },
        floatingExportImportOpen: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            tabs: [{
                name: 'Mark',
                active: true,
            }, {
                name: 'Averages',
                active: false,
            }, {
                name: 'Audit',
                active: false,
            }],
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
            },
        };
    },
    computed: {
        large() {
            const { deviceType } = this.$store.state;
            return ['desktop', 'laptop', 'tablet'].includes(deviceType);
        },
        tab() {
            return this.tabs.find((t) => t.active);
        },
        showPortlet() {
            return this.student && this.courseWork && this.large
                && this.teacher && this.show && this.markingPeriodAverage;
        },
        student() {
            if (!this.studentEnrollmentId) return null;
            return this.$_studentMixins_getStudentInCourse(this.studentEnrollmentId, this.courseSectionId);
        },
        markingPeriodAverage() {
            const { schoolTermMarkingPeriodId, student } = this;
            if (!student) return null;
            return (student.markingPeriodAverages || []).find((a) => a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        initials() {
            if (!this.student) return '';
            return this.student.lastName.substring(0, 2);
        },
        selectedGradingCategory() {
            return this.courseWork.categoryName;
        },
        category() {
            const { selectedGradingCategory } = this;
            return this.categories.find((cat) => cat.categoryName == selectedGradingCategory);
        },
        activeCell() {
            return this.$store.state.database.gradebook.activeCell;
        },
        linkingGuid() {
            return this.activeCell.linkingGuid;
        },
        courseWorkDomain() {
            return this.activeCell.courseWorkDomain;
        },
        studentEnrollmentId() {
            return this.activeCell.studentEnrollmentId;
        },
        courseSectionId() {
            if (!this.course) return null;
            return this.course.courseSectionId;
        },
        courseWork() {
            const { linkingGuid, courseWorkDomain } = this;
            if (!this.course) return null;
            const { courseSectionId } = this.course;
            if (courseWorkDomain == 'Local') {
                return this.$store.state.database.courseWork.find((cw) => cw.linkingGuid == linkingGuid && cw.courseSectionId == courseSectionId) || null;
            }
            if (courseWorkDomain == 'Google') {
                return this.$store.state.database.googleCourseWork.find((cw) => cw.linkingGuid == linkingGuid && cw.courseSectionId == courseSectionId) || null;
            }
            return null;
        },
        courseWorkGrade() {
            const { student, courseWork } = this;
            if (!courseWork || !student) return null;
            return getGradeForStudent(this.$store.state.database, courseWork, student);
        },
    },
    methods: {
        dragMouseDown(event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY}px`;
            this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX}px`;
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
        activateTab(tab) {
            this.tabs = this.tabs.map((t) => {
                const newTab = { ...t };
                newTab.active = t.name == tab.name;
                return newTab;
            });
        },
        moveFirst() {
            this.selectedPageIndex = 0;
        },
        moveLast() {
            this.selectedPageIndex = this.gradeChanges.length - 1;
        },
        openPanelForCourseWork(courseWork) {
            this.$_panelMixins_openPanelForCourseWork(courseWork);
        },
        openPanelForStudent(student) {
            const { course } = this;
            this.$_panelMixins_openPanelForStudent(student, course);
        },
        decimalToPercent(v) {
            let value = v;
            if (value === 0) return 0;
            if (!value) return null;
            value *= 100;
            const decimals = 2;
            // @ts-ignore
            return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
        },
    },
};

</script>

<style lang="scss" scoped>
.modal-sticky-md {
    padding: 0 !important;
    position: absolute !important;
    height: fit-content !important;
    resize: horizontal;
    left: auto;
    top: auto;
    margin: 0;
    right: 25px;
    bottom: 25px;
    width: 500px;
    max-width: 75vw;
    min-width: 320px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px  !important;
}

.kt-portlet__head {
    cursor: move !important;
}

</style>
