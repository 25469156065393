<template>
<b-modal
    ref="grade-transfer-modal"
    v-model="showModal"
    size="xl"
    no-close-on-backdrop
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title kt-font-danger">
            {{ extCourseSectionId }} Grade Transfer
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal()"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div
                    id="kt_wizard_v3"
                    class="kt-grid kt-wizard-v3 kt-wizard-v3--white x-hidden"
                    data-ktwizard-state="first"
                >
                    <div class="kt-grid__item">
                        <!--begin: Form Wizard Nav -->
                        <div class="kt-wizard-v3__nav w-100 transfer-nav-items">
                            <!--doc: Remove "kt-wizard-v3__nav-items--clickable" class and also set 'clickableSteps: false' in the JS init to disable manually clicking step titles -->
                            <div class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable">
                                <div
                                    class="kt-wizard-v3__nav-item"
                                    data-ktwizard-type="step"
                                    :data-ktwizard-state="currentStep === 1 ? 'current' : 'pending'"
                                    @click.stop.prevent="goToStep(1)"
                                >
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>1</span> Select Student
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar" />
                                    </div>
                                </div>
                                <div
                                    class="kt-wizard-v3__nav-item"
                                    data-ktwizard-type="step"
                                    :data-ktwizard-state="currentStep === 2 ? 'current' : 'pending'"
                                    @click.stop.prevent="goToStep(2)"
                                >
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>2</span> Course
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar" />
                                    </div>
                                </div>
                                <div
                                    class="kt-wizard-v3__nav-item"
                                    data-ktwizard-type="step"
                                    :data-ktwizard-state="currentStep === 3 ? 'current' : 'pending'"
                                    @click.stop.prevent="goToStep(3)"
                                >
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>3</span> Grade Transfer
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar" />
                                    </div>
                                </div>
                                <div
                                    class="kt-wizard-v3__nav-item"
                                    data-ktwizard-type="step"
                                    :data-ktwizard-state="currentStep === 4 ? 'current' : 'pending'"
                                    @click.stop.prevent="goToStep(4)"
                                >
                                    <div class="kt-wizard-v3__nav-body">
                                        <div class="kt-wizard-v3__nav-label">
                                            <span>4</span> Confirm
                                        </div>
                                        <div class="kt-wizard-v3__nav-bar" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--end: Form Wizard Nav -->
                    </div>
                    <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper w-100">
                        <!--begin: Form Wizard Form-->
                        <form
                            id="kt_form"
                            class="kt-form d-flex justify-content-center"
                        >
                            <!--begin: Form Wizard Step 1-->
                            <div
                                v-if="currentStep === 1"
                                class="kt-wizard-v3__content w-75"
                            >
                                <div class="row align-items-center pt-5 pb-5">
                                    <div class="kt-heading kt-heading--md col-5">
                                        Select a student
                                    </div>
                                    <div class="kt-form__section kt-form__section--first col-6">
                                        <div class="kt-wizard-v3__form">
                                            <div class="form-group">
                                                <select
                                                    v-if="!student"
                                                    v-model="studentEnrollmentId"
                                                    class="custom-select form-control"
                                                    @change="studentSelected($event)"
                                                >
                                                    <option :value="null" />
                                                    <option
                                                        v-for="student in courseStudents"
                                                        :key="`student_${student.studentEnrollmentId}`"
                                                        :value="student.studentEnrollmentId"
                                                    >
                                                        {{ `${student.lastName}, ${student.firstName}` }}
                                                    </option>
                                                </select>
                                                <div v-else>
                                                    <div class="kt-widget3">
                                                        <div class="kt-widget3__item">
                                                            <div class="kt-widget3__header">
                                                                <div class="kt-widget3__user-img">
                                                                    <user-avatar
                                                                        :avatar-user="student"
                                                                        class="image"
                                                                        style="max-width: 40px; width: 40px; border-radius: 10%;"
                                                                    >
                                                                        <div
                                                                            class="kt-widget__pic kt-font-boldest kt-font-light kt-widget__pic--primary kt-font-primary"
                                                                            style="max-width: 40px; width: 40px; height: 40px; font-size: 1.5rem; border-radius: 10%;"
                                                                        >
                                                                            {{ student.lastName.substring(0, 2) }}
                                                                        </div>
                                                                    </user-avatar>
                                                                </div>
                                                                <div class="kt-widget3__info">
                                                                    <span class="kt-widget3__username">
                                                                        {{ `${student.lastName}, ${student.firstName}` }}
                                                                    </span><br>
                                                                    <span class="kt-widget3__time">
                                                                        {{ `${course.extCourseSectionId}: ${course.courseSectionTitle}` }}
                                                                    </span>
                                                                </div>
                                                                <div class="kt-widget3__status">
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-outline-hover-danger"
                                                                        @click.stop.prevent="cancel"
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end: Form Wizard Step 1-->

                            <!--begin: Form Wizard Step 2-->
                            <div
                                v-if="currentStep === 2"
                                class="kt-wizard-v3__content w-75"
                            >
                                <div class="kt-heading kt-heading--md">
                                    Course to transfer grades from
                                </div>
                                <div class="kt-form__section kt-form__section--first">
                                    <div class="kt-wizard-v3__form">
                                        <div class="form-group row align-items-center pt-1 pb-3">
                                            <div class="col-5">
                                                <div v-if="!transferFromCourse">
                                                    <select
                                                        v-model="transferFromCourseSectionId"
                                                        class="custom-select form-control"
                                                        :disabled="!transferableCourses.length"
                                                        @change="courseSelected($event)"
                                                    >
                                                        <option
                                                            v-for="course in transferableCourses"
                                                            :key="`transferableCourse_${course.extCourseSectionId}`"
                                                            :value="course.courseSectionId"
                                                        >
                                                            {{ activeCoursesForStudent.filter((c) => c.courseSectionId == course.courseSectionId).length > 0 ? '' : 'Dropped:' }}
                                                            {{ `${course.extCourseSectionId}: ${course.courseSectionTitle}` }}
                                                        </option>
                                                        <option
                                                            v-if="!transferableCourses.length"
                                                            :value="null"
                                                        >
                                                            Student not enrolled in any course sections that have transferable Grades
                                                        </option>
                                                    </select>
                                                </div>
                                                <div v-else>
                                                    <div class="row align-items-center">
                                                        <button
                                                            type="button"
                                                            class="btn btn-outline-hover-info btn-elevate btn-icon btn-circle"
                                                            @click.stop.prevent="removeCourse"
                                                        >
                                                            <i class="flaticon2-reload" />
                                                        </button>
                                                        <div class="kt-widget33 col-9">
                                                            <div class="kt-widget33__body">
                                                                <span class="kt-widget33__title kt-font-primary">
                                                                    {{ transferFromCourse.courseSectionTitle }}
                                                                </span>
                                                                <span class="kt-widget33__desc">
                                                                    {{ `${transferFromCourse.extCourseSectionId}: ${transferFromCourse.name}` }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1 d-flex justify-content-center">
                                                <span>
                                                    <i class="fa fa-arrow-right fa-2x" />
                                                </span>
                                            </div>
                                            <div class="col-5 d-flex justify-content-center">
                                                <div class="kt-widget33">
                                                    <div class="kt-widget33__body">
                                                        <span class="kt-widget33__title kt-font-primary">
                                                            {{ course.courseSectionTitle }}
                                                        </span>
                                                        <span class="kt-widget33__desc">
                                                            {{ `${course.extCourseSectionId}: ${course.name}` }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="transferFromCourse && conflictingGradeTemplates"
                                            class="form-group pt-3"
                                        >
                                            <div
                                                class="alert alert-secondary"
                                                role="alert"
                                            >
                                                <div class="alert-icon">
                                                    <i class="flaticon-danger kt-font-danger" />
                                                </div>
                                                <div class="alert-text">
                                                    The grade template of <code>{{ transferFromCourse.extCourseSectionId }}</code> does not match
                                                    the grade template of <code>{{ course.extCourseSectionId }}</code>.
                                                    This may cause inconsistent averages after the transfer.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end: Form Wizard Step 2-->

                            <!--begin: Form Wizard Step 3-->
                            <div
                                v-if="currentStep === 3 && transferFromCourse"
                                class="kt-wizard-v3__content w-100"
                            >
                                <div class="header-margin">
                                    <div class="kt-heading kt-heading--md mb-0">
                                        Transfer from <code>{{ `${transferFromCourse.extCourseSectionId}: ${transferFromCourse.courseSectionTitle}` }}</code>
                                    </div>
                                    <div class="text-muted mt-3">
                                        Map Assignments into {{ `${course.courseSectionTitle || course.name}` }}
                                    </div>
                                </div>
                                <div class="kt-form__section kt-form__section--first">
                                    <div class="kt-wizard-v3__form">
                                        <div class="form-group">
                                            <b-table
                                                v-if="transferFromCourseWork.length"
                                                :items="transferableCourseWork"
                                                :fields="fields"
                                            >
                                                <template #cell(newCourseWorkId)="data">
                                                    <select
                                                        v-model="data.item.newCourseWorkId"
                                                        class="custom-select form-control col-md-10"
                                                    >
                                                        <option :value="null" />
                                                        <option
                                                            v-for="(a, idx) in courseCourseWork"
                                                            :key="`option_courseWork_${a.courseWorkId}_${idx}`"
                                                            :value="a.courseWorkId"
                                                        >
                                                            {{ a.courseWorkTitle }}
                                                        </option>
                                                    </select>
                                                    <span
                                                        v-if="data.item.newCourseWorkId && maxPointCheck(data.item)"
                                                        v-b-tooltip.hover="'New grade exceeds assignment max points'"
                                                        class="ml-3"
                                                    >
                                                        <i class="flaticon-danger kt-font-danger fa-lg" />
                                                    </span>
                                                </template>
                                            </b-table>
                                            <div v-else>
                                                No Assignments to transfer grades from
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end: Form Wizard Step 3-->

                            <!--begin: Form Wizard Step 4-->
                            <div
                                v-if="currentStep === 4 && transferFromCourse"
                                class="kt-wizard-v3__content w-75"
                            >
                                <div class="kt-heading kt-heading--md">
                                    Transferring <code>{{ transferableCourseWork.filter((a) => a.newCourseWorkId).length }}</code> assignments for {{ `${student.lastName}, ${student.firstName}` }}
                                </div>
                                <div class="kt-form__section kt-form__section--first">
                                    <div class="kt-wizard-v3__form">
                                        <div class="form-group row align-items-center pt-1 pb-3">
                                            <div class="col-5">
                                                <div class="kt-widget33">
                                                    <div class="kt-widget33__body">
                                                        <span class="kt-widget33__title kt-font-primary">
                                                            {{ transferFromCourse.courseSectionTitle }}
                                                        </span>
                                                        <span class="kt-widget33__desc">
                                                            {{ `${transferFromCourse.extCourseSectionId}: ${transferFromCourse.name}` }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1 d-flex justify-content-center">
                                                <span>
                                                    <i class="fa fa-arrow-right fa-2x" />
                                                </span>
                                            </div>
                                            <div class="col-5 d-flex justify-content-center">
                                                <div class="kt-widget33">
                                                    <div class="kt-widget33__body">
                                                        <span class="kt-widget33__title kt-font-primary">
                                                            {{ course.courseSectionTitle }}
                                                        </span>
                                                        <span class="kt-widget33__desc">
                                                            {{ `${course.extCourseSectionId}: ${course.name}` }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="transferFromCourse && conflictingGradeTemplates"
                                            class="form-group pt-3"
                                        >
                                            <div
                                                class="alert alert-secondary"
                                                role="alert"
                                            >
                                                <div class="alert-icon">
                                                    <i class="flaticon-danger kt-font-danger" />
                                                </div>
                                                <div class="alert-text">
                                                    The grade template of <code>{{ transferFromCourse.extCourseSectionId }}</code> does not match
                                                    the grade template of <code>{{ course.extCourseSectionId }}</code>.
                                                    This may cause inconsistent averages after the transfer.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end: Form Wizard Step 4-->
                        </form>
                        <!--end: Form Wizard Form-->
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            v-if="currentStep !== 1"
            type="button"
            class="btn btn-secondary alignment pull-right"
            @click.stop.prevent="goToPrevStep"
        >
            Back
        </button>
        <button
            type="button"
            class="btn btn-primary alignment pull-right"
            :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
            data-dismiss="modal"
            :disabled="saving || !isValid"
            @click.stop.prevent="goToNextStep"
        >
            {{ currentStep === 4 ? 'Transfer' : 'Continue' }}
        </button>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';
import async from 'async';
import Types from '../store/Types';
import UserAvatar from './UserAvatar.vue';
import studentMixins from '../store/mixins/studentMixins';
import courseMixins, { getCourses } from '../store/mixins/courseMixins';
import { getCourseWork } from '../store/mixins/courseWorkMixins';
import * as network from '../network';

export default {
    name: 'CourseGradeTransferModal',
    components: {
        UserAvatar,
    },
    mixins: [courseMixins, studentMixins],
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        toggleShowGradeTransfer: {
            type: Function,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        },
        incrementKey: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            currentStep: 1,
            saving: false,
            changes: [],
            student: null,
            studentEnrollmentId: null,
            studentCourseSectionStudents: null,
            studentCourseWorkGrades: null,
            transferFromCourse: null,
            transferFromCourseSectionId: null,
            transferFromCourseWork: null,
            transferableCourseWork: [],
            fields: [
                {
                    key: 'mark',
                    label: 'Student Grade',
                },
                {
                    key: 'courseWorkTitle',
                    label: 'From Assignment',
                },
                {
                    key: 'newCourseWorkId',
                    label: 'To Assignment',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        courses() {
            const { database } = this.$store.state;
            return getCourses(database);
        },
        courseStudents() {
            const { course } = this;
            if (!course) return [];
            const students = this.$_studentMixins_getStudentsForCourse(course);
            return students;
        },
        activeCoursesForStudent() {
            const { studentEnrollmentId } = this;
            if (!studentEnrollmentId) return [];
            return this.$_courseMixins_getCoursesForStudentEnrollmentId(studentEnrollmentId);
        },
        transferableCourses() {
            const { studentCourseSectionStudents, course } = this;
            if (!studentCourseSectionStudents || !studentCourseSectionStudents.length) return [];
            const courses = [];
            studentCourseSectionStudents.forEach((s) => {
                const { courseSectionId } = s;
                courses.push(this.$_courseMixins_getCourseById(courseSectionId));
            });
            return courses.filter((a) => a.courseSectionId != course.courseSectionId);
        },
        courseCourseWork() {
            const { course } = this;
            if (!course) return [];
            const { database } = this.$store.state;
            const courseWork = getCourseWork(database, course);
            const { courseSectionId } = course;
            return courseWork.filter((a) => !a.deleted && a.courseWorkDomain === 'Local' && a.courseSectionId == courseSectionId).sort((a, b) => a.courseWorkTitle.localeCompare(b.courseWorkTitle));
        },
        conflictingGradeTemplates() {
            const { transferFromCourse, course } = this;
            if (!transferFromCourse) return false;
            const transferGradeTemplate = transferFromCourse.gradeTemplate;
            const { gradeTemplate } = course;
            if (!transferGradeTemplate && !gradeTemplate) return false;
            if (!transferGradeTemplate || !gradeTemplate) return true;
            return transferGradeTemplate.gradeTemplateId !== gradeTemplate.gradeTemplateId;
        },
        isValid() {
            const { currentStep } = this;
            if (currentStep === 1) {
                const { student } = this;
                if (student) return true;
                return false;
            }
            if (currentStep === 2 || currentStep === 3) {
                const { transferFromCourse } = this;
                if (transferFromCourse) return true;
                return false;
            }
            if (currentStep === 4) {
                const { transferableCourseWork } = this;
                if (!transferableCourseWork.length) return false;
                const some = transferableCourseWork.some((a) => a.newCourseWorkId);
                return some;
            }
            return false;
        },
        showModal: {
            get() {
                return this.show;
            },
            set(val) {
                this.toggleShowGradeTransfer();
            },
        },
    },
    methods: {
        closeModal() {
            this.cancel();
            this.showModal = false;
        },
        openModal() {
            this.showModal = true;
        },
        cancel() {
            this.student = null;
            this.studentEnrollmentId = null;
            this.studentCourseSectionStudents = null;
            this.studentCourseWorkGrades = null;
            this.transferFromCourse = null;
            this.transferFromCourseSectionId = null;
            this.transferFromCourseWork = null;
            this.transferableCourseWork = [];
            this.currentStep = 1;
        },
        removeCourse() {
            this.transferFromCourse = null;
            this.transferFromCourseSectionId = null;
            this.transferFromCourseWork = null;
            this.transferableCourseWork = [];
        },
        studentSelected(event) {
            const v = this;
            const student = v.courseStudents.find((s) => s.studentEnrollmentId == event.target.value);

            const { studentEnrollmentId } = student;
            const { schoolId, schoolTermId } = v.user.school;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    studentEnrollmentId,
                },
            };
            network.courseSections.getAllCourseSectionStudentsForEnrollment(params, (err, res) => {
                if (err) return v.showError('Unable to get all courses for student');
                v.student = student;
                v.studentCourseSectionStudents = res.courseSectionStudents;
            });
        },
        courseSelected(event) {
            const v = this;
            const { student } = this;
            if (!student) return v.showError('No student selected');
            const { studentEnrollmentId } = student;

            const { schoolId, schoolTermId } = v.user.school;
            const course = v.courses.find((c) => c.courseSectionId == event.target.value);
            if (!course) return v.showError('Unable to find course to transfer grades from');

            const { courseSectionId } = course;
            async.auto({
                courseWork(next) {
                    const params = {
                        url: {
                            courseSectionId,
                            schoolId,
                            schoolTermId,
                        },
                    };
                    network.courseWork.getCourseWorkByCourseSectionId(params, (err, res) => {
                        if (err) return next(err);
                        next(null, res.courseWork);
                    });
                },
                courseWorkGrades(next) {
                    const params = {
                        url: {
                            schoolId,
                            schoolTermId,
                            studentEnrollmentId,
                        },
                    };
                    network.courseWorkGrades.getCourseWorkGradesByStudentEnrollmentId(params, (err, res) => {
                        if (err) return next(err);
                        next(null, res.courseWorkGrades);
                    });
                },
            }, (err, results) => {
                if (err) return v.showError('Unable to get course work for course');
                const { courseWork, courseWorkGrades } = results;
                v.transferFromCourse = course;
                v.transferFromCourseWork = courseWork;
                v.studentCourseWorkGrades = courseWorkGrades;
                v.setTransferableCourseWork();
            });
        },
        setTransferableCourseWork() {
            const { transferFromCourseWork, studentCourseWorkGrades } = this;
            if (!transferFromCourseWork) return;
            this.transferableCourseWork = transferFromCourseWork.map((a) => {
                if (!studentCourseWorkGrades) return a;
                const grade = studentCourseWorkGrades.find((g) => g.courseWorkId == a.courseWorkId && g.courseSectionId == a.courseSectionId && !g.deleted);
                if (!grade) return a;
                return {
                    ...a,
                    grade,
                    mark: grade.mark !== null ? grade.mark : '-',
                };
            }).filter((a) => !a.deleted && a.courseWorkDomain === 'Local');
        },
        maxPointCheck(courseWork) {
            const { courseCourseWork } = this;
            const newCourseWork = courseCourseWork.find((a) => a.courseWorkId == courseWork.newCourseWorkId);
            if (!newCourseWork) return false;
            const { maxPoints } = newCourseWork;
            const { mark } = courseWork;
            if (Number.isNaN(parseFloat(mark))) return false;
            if (parseFloat(mark) > maxPoints) return true;
        },
        goToPrevStep() {
            if (this.currentStep !== 1) {
                this.currentStep -= 1;
            }
        },
        goToNextStep() {
            if (this.currentStep !== 4) {
                this.currentStep += 1;
                return;
            }
            if (this.currentStep === 4) {
                this.save();
            }
        },
        goToStep(step) {
            if (step === 1) {
                this.currentStep = step;
            } else if (step === 2) {
                const { student } = this;
                if (!student) return;
                this.currentStep = step;
            } else if (step === 3 || step === 4) {
                const { transferFromCourse } = this;
                if (!transferFromCourse) return;
                this.currentStep = step;
            }
        },
        save() {
            const v = this;
            const {
                saving, transferableCourseWork, student, course,
            } = this;
            const { studentEnrollmentId } = student;
            const { schoolId, schoolTermId, schoolStaffId } = v.user.school;
            const { courseSectionId } = course;
            if (saving) return;
            v.saving = true;

            const courseWorkGrades = transferableCourseWork.filter((a) => a.newCourseWorkId && a.grade && !a.grade.deleted)
                .map((a) => ({
                    schoolTermId: a.schoolTermId,
                    courseSectionId,
                    courseWorkId: a.newCourseWorkId,
                    studentEnrollmentId,
                    mark: a.grade.mark,
                    deleted: false,
                }));

            const unique = new Map();
            courseWorkGrades.forEach((grade) => {
                unique.set(`${grade.courseSectionId}_${grade.courseWorkId}`, grade);
            });

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolStaffId,
                },
                body: {
                    courseWorkGrades: [...unique.values()],
                },
            };

            network.courseWorkGrades.saveCourseWorkGrades(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Error transferring course work grades');
                v.showNotification('Course Work Grades successfully transferred');
                v.$store.commit(Types.mutations.SET_DB_COURSE_WORK_GRADES, res.courseWorkGrades);
                if (res.invalidGrades.length > 0) {
                    v.showNotification('Some grades were not transferred because they were invalid');
                }
                v.incrementKey();
                v.closeModal();
            });
        },
    },
};
</script>

<style scoped src='../css/wizard3.css' lang="css" />

<style scoped>
.header-margin {
  margin-left: 6% !important;
}
.modal-body {
  max-height: 70vh;
  height: 70vh !important;
  overflow-y: auto;
}
.transfer-nav-items {
  margin-left: 9% !important;
}
.x-hidden {
  overflow-x: hidden !important;
}
</style>
