var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "grade-transfer-modal",
    attrs: { size: "xl", "no-close-on-backdrop": "", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title kt-font-danger" }, [
              _vm._v(" " + _vm._s(_vm.extCourseSectionId) + " Grade Transfer "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                { staticClass: "kt-portlet__body kt-portlet__body--fit" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-grid kt-wizard-v3 kt-wizard-v3--white x-hidden",
                      attrs: {
                        id: "kt_wizard_v3",
                        "data-ktwizard-state": "first",
                      },
                    },
                    [
                      _c("div", { staticClass: "kt-grid__item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-wizard-v3__nav w-100 transfer-nav-items",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v3__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.currentStep === 1
                                          ? "current"
                                          : "pending",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.goToStep(1)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-wizard-v3__nav-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-wizard-v3__nav-label",
                                          },
                                          [
                                            _c("span", [_vm._v("1")]),
                                            _vm._v(" Select Student "),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "kt-wizard-v3__nav-bar",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v3__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.currentStep === 2
                                          ? "current"
                                          : "pending",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.goToStep(2)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-wizard-v3__nav-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-wizard-v3__nav-label",
                                          },
                                          [
                                            _c("span", [_vm._v("2")]),
                                            _vm._v(" Course "),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "kt-wizard-v3__nav-bar",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v3__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.currentStep === 3
                                          ? "current"
                                          : "pending",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.goToStep(3)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-wizard-v3__nav-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-wizard-v3__nav-label",
                                          },
                                          [
                                            _c("span", [_vm._v("3")]),
                                            _vm._v(" Grade Transfer "),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "kt-wizard-v3__nav-bar",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v3__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.currentStep === 4
                                          ? "current"
                                          : "pending",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.goToStep(4)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-wizard-v3__nav-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-wizard-v3__nav-label",
                                          },
                                          [
                                            _c("span", [_vm._v("4")]),
                                            _vm._v(" Confirm "),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "kt-wizard-v3__nav-bar",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper w-100",
                        },
                        [
                          _c(
                            "form",
                            {
                              staticClass:
                                "kt-form d-flex justify-content-center",
                              attrs: { id: "kt_form" },
                            },
                            [
                              _vm.currentStep === 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "kt-wizard-v3__content w-75",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row align-items-center pt-5 pb-5",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-heading kt-heading--md col-5",
                                            },
                                            [_vm._v(" Select a student ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-form__section kt-form__section--first col-6",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-wizard-v3__form",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      !_vm.student
                                                        ? _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.studentEnrollmentId,
                                                                  expression:
                                                                    "studentEnrollmentId",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "custom-select form-control",
                                                              on: {
                                                                change: [
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.studentEnrollmentId =
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.studentSelected(
                                                                      $event
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c("option", {
                                                                domProps: {
                                                                  value: null,
                                                                },
                                                              }),
                                                              _vm._l(
                                                                _vm.courseStudents,
                                                                function (
                                                                  student
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: `student_${student.studentEnrollmentId}`,
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            student.studentEnrollmentId,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            `${student.lastName}, ${student.firstName}`
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "kt-widget3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "kt-widget3__item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "kt-widget3__header",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "kt-widget3__user-img",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "user-avatar",
                                                                              {
                                                                                staticClass:
                                                                                  "image",
                                                                                staticStyle:
                                                                                  {
                                                                                    "max-width":
                                                                                      "40px",
                                                                                    width:
                                                                                      "40px",
                                                                                    "border-radius":
                                                                                      "10%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    "avatar-user":
                                                                                      _vm.student,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "kt-widget__pic kt-font-boldest kt-font-light kt-widget__pic--primary kt-font-primary",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "max-width":
                                                                                          "40px",
                                                                                        width:
                                                                                          "40px",
                                                                                        height:
                                                                                          "40px",
                                                                                        "font-size":
                                                                                          "1.5rem",
                                                                                        "border-radius":
                                                                                          "10%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.student.lastName.substring(
                                                                                            0,
                                                                                            2
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "kt-widget3__info",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "kt-widget3__username",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      `${_vm.student.lastName}, ${_vm.student.firstName}`
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "kt-widget3__time",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      `${_vm.course.extCourseSectionId}: ${_vm.course.courseSectionTitle}`
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "kt-widget3__status",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "button",
                                                                              {
                                                                                staticClass:
                                                                                  "btn btn-outline-hover-danger",
                                                                                attrs:
                                                                                  {
                                                                                    type: "button",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                      $event.preventDefault()
                                                                                      return _vm.cancel.apply(
                                                                                        null,
                                                                                        arguments
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Remove "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentStep === 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "kt-wizard-v3__content w-75",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-heading kt-heading--md",
                                        },
                                        [
                                          _vm._v(
                                            " Course to transfer grades from "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-form__section kt-form__section--first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "kt-wizard-v3__form",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group row align-items-center pt-1 pb-3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      !_vm.transferFromCourse
                                                        ? _c("div", [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.transferFromCourseSectionId,
                                                                    expression:
                                                                      "transferFromCourseSectionId",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "custom-select form-control",
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .transferableCourses
                                                                      .length,
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal =
                                                                        Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                      _vm.transferFromCourseSectionId =
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.courseSelected(
                                                                        $event
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                              [
                                                                _vm._l(
                                                                  _vm.transferableCourses,
                                                                  function (
                                                                    course
                                                                  ) {
                                                                    return _c(
                                                                      "option",
                                                                      {
                                                                        key: `transferableCourse_${course.extCourseSectionId}`,
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              course.courseSectionId,
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.activeCoursesForStudent.filter(
                                                                                (
                                                                                  c
                                                                                ) =>
                                                                                  c.courseSectionId ==
                                                                                  course.courseSectionId
                                                                              )
                                                                                .length >
                                                                                0
                                                                                ? ""
                                                                                : "Dropped:"
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              `${course.extCourseSectionId}: ${course.courseSectionTitle}`
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                !_vm
                                                                  .transferableCourses
                                                                  .length
                                                                  ? _c(
                                                                      "option",
                                                                      {
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              null,
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Student not enrolled in any course sections that have transferable Grades "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                          ])
                                                        : _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-outline-hover-info btn-elevate btn-icon btn-circle",
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                          return _vm.removeCourse.apply(
                                                                            null,
                                                                            arguments
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "flaticon2-reload",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "kt-widget33 col-9",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "kt-widget33__body",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "kt-widget33__title kt-font-primary",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .transferFromCourse
                                                                                    .courseSectionTitle
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "kt-widget33__desc",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  `${_vm.transferFromCourse.extCourseSectionId}: ${_vm.transferFromCourse.name}`
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-1 d-flex justify-content-center",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-arrow-right fa-2x",
                                                        }),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-5 d-flex justify-content-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget33",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "kt-widget33__body",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-widget33__title kt-font-primary",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .course
                                                                          .courseSectionTitle
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-widget33__desc",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        `${_vm.course.extCourseSectionId}: ${_vm.course.name}`
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.transferFromCourse &&
                                              _vm.conflictingGradeTemplates
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group pt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "alert alert-secondary",
                                                          attrs: {
                                                            role: "alert",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "alert-icon",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "flaticon-danger kt-font-danger",
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "alert-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " The grade template of "
                                                              ),
                                                              _c("code", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .transferFromCourse
                                                                      .extCourseSectionId
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " does not match the grade template of "
                                                              ),
                                                              _c("code", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.course
                                                                      .extCourseSectionId
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                ". This may cause inconsistent averages after the transfer. "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentStep === 3 && _vm.transferFromCourse
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-wizard-v3__content w-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-margin" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-heading kt-heading--md mb-0",
                                            },
                                            [
                                              _vm._v(" Transfer from "),
                                              _c("code", [
                                                _vm._v(
                                                  _vm._s(
                                                    `${_vm.transferFromCourse.extCourseSectionId}: ${_vm.transferFromCourse.courseSectionTitle}`
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "text-muted mt-3" },
                                            [
                                              _vm._v(
                                                " Map Assignments into " +
                                                  _vm._s(
                                                    `${
                                                      _vm.course
                                                        .courseSectionTitle ||
                                                      _vm.course.name
                                                    }`
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-form__section kt-form__section--first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "kt-wizard-v3__form",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm.transferFromCourseWork
                                                    .length
                                                    ? _c("b-table", {
                                                        attrs: {
                                                          items:
                                                            _vm.transferableCourseWork,
                                                          fields: _vm.fields,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "cell(newCourseWorkId)",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              data
                                                                                .item
                                                                                .newCourseWorkId,
                                                                            expression:
                                                                              "data.item.newCourseWorkId",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "custom-select form-control col-md-10",
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal =
                                                                              Array.prototype.filter
                                                                                .call(
                                                                                  $event
                                                                                    .target
                                                                                    .options,
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    return o.selected
                                                                                  }
                                                                                )
                                                                                .map(
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    var val =
                                                                                      "_value" in
                                                                                      o
                                                                                        ? o._value
                                                                                        : o.value
                                                                                    return val
                                                                                  }
                                                                                )
                                                                            _vm.$set(
                                                                              data.item,
                                                                              "newCourseWorkId",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                null,
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.courseCourseWork,
                                                                        function (
                                                                          a,
                                                                          idx
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key: `option_courseWork_${a.courseWorkId}_${idx}`,
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    a.courseWorkId,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    a.courseWorkTitle
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                  data.item
                                                                    .newCourseWorkId &&
                                                                  _vm.maxPointCheck(
                                                                    data.item
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "b-tooltip",
                                                                                rawName:
                                                                                  "v-b-tooltip.hover",
                                                                                value:
                                                                                  "New grade exceeds assignment max points",
                                                                                expression:
                                                                                  "'New grade exceeds assignment max points'",
                                                                                modifiers:
                                                                                  {
                                                                                    hover: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "ml-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "flaticon-danger kt-font-danger fa-lg",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          789474694
                                                        ),
                                                      })
                                                    : _c("div", [
                                                        _vm._v(
                                                          " No Assignments to transfer grades from "
                                                        ),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentStep === 4 && _vm.transferFromCourse
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "kt-wizard-v3__content w-75",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-heading kt-heading--md",
                                        },
                                        [
                                          _vm._v(" Transferring "),
                                          _c("code", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.transferableCourseWork.filter(
                                                  (a) => a.newCourseWorkId
                                                ).length
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            " assignments for " +
                                              _vm._s(
                                                `${_vm.student.lastName}, ${_vm.student.firstName}`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-form__section kt-form__section--first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "kt-wizard-v3__form",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group row align-items-center pt-1 pb-3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget33",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "kt-widget33__body",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-widget33__title kt-font-primary",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .transferFromCourse
                                                                          .courseSectionTitle
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-widget33__desc",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        `${_vm.transferFromCourse.extCourseSectionId}: ${_vm.transferFromCourse.name}`
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-1 d-flex justify-content-center",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-arrow-right fa-2x",
                                                        }),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-5 d-flex justify-content-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget33",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "kt-widget33__body",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-widget33__title kt-font-primary",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .course
                                                                          .courseSectionTitle
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-widget33__desc",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        `${_vm.course.extCourseSectionId}: ${_vm.course.name}`
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.transferFromCourse &&
                                              _vm.conflictingGradeTemplates
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group pt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "alert alert-secondary",
                                                          attrs: {
                                                            role: "alert",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "alert-icon",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "flaticon-danger kt-font-danger",
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "alert-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " The grade template of "
                                                              ),
                                                              _c("code", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .transferFromCourse
                                                                      .extCourseSectionId
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " does not match the grade template of "
                                                              ),
                                                              _c("code", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.course
                                                                      .extCourseSectionId
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                ". This may cause inconsistent averages after the transfer. "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _vm.currentStep !== 1
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary alignment pull-right",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.goToPrevStep.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Back ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-primary alignment pull-right",
                class: {
                  "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                },
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  disabled: _vm.saving || !_vm.isValid,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.goToNextStep.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.currentStep === 4 ? "Transfer" : "Continue") +
                    " "
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }