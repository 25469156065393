import { parse } from 'csv-parse';
import async from 'async';

/**
 * @param {Object} user
 * @param {Function} callback
 */
export function gradeBookImport(file, user, callback) {
    const fileName = file.name;

    async.auto({
        validate(next) {
            if (!fileName.endsWith('.csv')) return next(new Error('Invalid file type'));
            return next();
        },
        read: ['validate', (results, next) => {
            const options = {
                auto_parse: false,
                delimiter: ',',
                trim: true,
                columns: true,
                skip_empty_lines: true,
                relax_column_count: true,
                quote: '"',
            };

            try {
                const reader = new FileReader();
                reader.onload = () => {
                    parse(reader.result.toString(), options, (err, rows) => {
                        if (err) return next(err);
                        const output = rows.map((row) => {
                            const record = { ...row };

                            if (!record['DO NOT EDIT/ALTER ROW'] || record['DO NOT EDIT/ALTER ROW'] === 'Student') return null;
                            record.student = record['DO NOT EDIT/ALTER ROW'].trim();
                            delete record['DO NOT EDIT/ALTER ROW'];
                            delete record[''];

                            return record;
                        }).filter((row) => row);
                        if (!output.length) return next(new Error('Not valid GradeBook import format or No valid rows'));

                        return next(null, output);
                    });
                };
                reader.readAsText(file);
            } catch (err) {
                next(err);
            }
        }],
    }, (err, results) => {
        if (err) return callback(err);
        return callback(null, results.read);
    });
}

/**
 * @param {Object} user
 * @param {Function} callback
 */
export function googleImport(file, user, callback) {
    const fileName = file.name;

    async.auto({
        validate(next) {
            if (!fileName.endsWith('.csv')) return next(new Error('Invalid file type'));
            return next();
        },
        read: ['validate', (results, next) => {
            const options = {
                auto_parse: false,
                delimiter: ',',
                trim: true,
                columns: true,
                skip_empty_lines: true,
                relax_column_count: true,
                quote: '"',
            };

            try {
                const reader = new FileReader();
                reader.onload = () => {
                    parse(reader.result.toString(), options, (err, rows) => {
                        if (err) return next(err);

                        const students = [];
                        const courseWork = {};
                        rows.forEach((row, idx) => {
                            const record = { ...row };

                            record.email = record['Email Address'].trim();
                            record.lastName = record['Last Name'].trim();
                            record.firstName = record['First Name'].trim();
                            delete record['Email Address'];
                            delete record['Last Name'];
                            delete record['First Name'];

                            Object.entries(record).filter(([key, value]) => !['student', 'email', 'lastName', 'firstName'].includes(key)).forEach(([key, value], idx) => {
                                record[key] = {
                                    value,
                                    index: idx,
                                }
                            });

                            if (record.email) return students.push(record);

                            if (record.lastName === 'Date') { // Date row
                                Object.keys(record).filter((key) => !['student', 'lastName', 'email', 'firstName'].includes(key)).forEach((key, idx) => {
                                    courseWork[key] = { title: key, date: record[key].value, index: idx };
                                });
                            }
                            if (record.lastName === 'Points') { // Points row
                                Object.keys(record).filter((key) => !['student', 'lastName', 'email', 'firstName'].includes(key)).forEach((key) => {
                                    courseWork[key].points = record[key].value;
                                });
                            }
                        });
                        const output = [courseWork, ...students];
                        if (!students.length || !Object.keys(courseWork).length) return next(new Error('Not enough Google Import Data'));

                        return next(null, output);
                    });
                };
                reader.readAsText(file);
            } catch (err) {
                next(err);
            }
        }],
    }, (err, results) => {
        if (err) return callback(err);
        return callback(null, results.read);
    });
}
