var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "draggableContainer", staticClass: "draggable-container" },
    [
      _vm.show
        ? _c(
            "div",
            {
              staticClass:
                "modal modal-sticky-bottom-right modal-sticky-lg show d-block",
              staticStyle: { overflow: "visible", position: "relative" },
              style: {
                maxWidth: _vm.googleCourse ? "650px" : "950px",
                width: _vm.googleCourse ? "650px" : "950px",
              },
              attrs: {
                role: "dialog",
                "data-backdrop": "false",
                "aria-modal": "true",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog",
                  style: {
                    maxWidth: _vm.googleCourse ? "650px" : "950px",
                    width: _vm.googleCourse ? "650px" : "950px",
                  },
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _c(
                      "div",
                      { staticClass: "kt-portlet kt-portlet--mobile" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kt-portlet__head",
                            on: { mousedown: _vm.dragMouseDown },
                          },
                          [
                            _vm._m(0),
                            _c(
                              "div",
                              { staticClass: "kt-portlet__head-toolbar" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "kt-portlet__head-actions" },
                                  [
                                    !_vm.importingGrades
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-icon",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.toggleShowImportExport.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "la la-close",
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-icon btn-clean btn-icon-md",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.toggleSize.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              class: [
                                                "la",
                                                _vm.minimized
                                                  ? "la-angle-up"
                                                  : "la-angle-down",
                                              ],
                                            }),
                                          ]
                                        ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "kt-portlet__body",
                            class: [_vm.minimized ? "d-none" : ""],
                          },
                          [
                            !_vm.importingGrades
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row row-no-padding row-col-separator-xl",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "px-4",
                                        class: {
                                          "col-xl-6": _vm.googleCourse,
                                          "col-xl-4": !_vm.googleCourse,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row align-items-center w-100 pl-2",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "kt-font-lg" },
                                              [_vm._v(" Export Data ")]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-clean btn-icon ml-auto",
                                                attrs: {
                                                  type: "button",
                                                  disabled: !_vm.hasGrades,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.exportData.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "flaticon-download-1",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._m(1),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "px-4",
                                        class: {
                                          "col-xl-6": _vm.googleCourse,
                                          "col-xl-4": !_vm.googleCourse,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row align-items-center w-100 pl-2",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "kt-font-lg" },
                                              [_vm._v(" Import Data ")]
                                            ),
                                            _c("input", {
                                              staticStyle: { display: "none" },
                                              attrs: {
                                                id: "file-input",
                                                type: "file",
                                                name: "file",
                                                accept: ".csv",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onFileBrowseSelect(
                                                    $event,
                                                    false
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-clean btn-icon ml-auto",
                                                attrs: {
                                                  type: "button",
                                                  disabled:
                                                    !_vm.canEditGradebook ||
                                                    !_vm.hasGrades,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.triggerFileBrowser(
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "flaticon-upload",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._m(2),
                                      ]
                                    ),
                                    !_vm.googleCourse
                                      ? _c(
                                          "div",
                                          { staticClass: "col-xl-4 px-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row align-items-center w-100 pl-2",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-font-lg kt-font-google kt-font-bolder",
                                                  },
                                                  [_vm._v(" Google Import ")]
                                                ),
                                                _c("input", {
                                                  staticStyle: {
                                                    display: "none",
                                                  },
                                                  attrs: {
                                                    id: "google-file-input",
                                                    type: "file",
                                                    name: "file",
                                                    accept: ".csv",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onFileBrowseSelect(
                                                        $event,
                                                        true
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-clean-google-custom btn-icon ml-auto",
                                                    attrs: {
                                                      type: "button",
                                                      disabled:
                                                        !_vm.canEditGradebook,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.triggerFileBrowser(
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "flaticon-upload",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._m(3),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _c("div", { staticClass: "kt-section" }, [
                                  _vm._m(4),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-section__content--solid mt-3",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-label-danger mr-2",
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.saving,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.cancelImport.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel Save ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-success mr-2",
                                          class: {
                                            "kt-spinner kt-spinner--sm kt-spinner--light":
                                              _vm.saving,
                                          },
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.saving,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.saveImportedData.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Save Grades ")]
                                      ),
                                    ]
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Gradebook Export / Import "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("small", [
        _vm._v(
          " This download is required to be used as a template for importing data "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("small", [
        _vm._v(
          " Import the gradebook Export CSV with changes to update grades "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("small", [
        _vm._v(
          " Import the Google Classroom CSV to update grades and assignments "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-section__content" }, [
      _c("div", { staticClass: "row align-items-center ml-2" }, [
        _c("span", { staticClass: "mr-3" }, [
          _vm._v(
            " The Gradebook has been repopulated with your imported data to review. Revise the changes, edit any invalid grades, and select Save Grades to complete import. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }