var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.change
      ? _c("div", [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v("There are no changes to audit."),
          ]),
        ])
      : _c(
          "div",
          { key: `change_${_vm.change.pageNumber}`, staticClass: "row pb-4" },
          [
            _c("div", { staticClass: "col-4" }, [
              _c("div", {}, [
                _c("div", [_vm._v("When")]),
                _c("div", { staticClass: "kt-font-bold" }, [
                  _vm._v(" " + _vm._s(_vm.change.relativeDate) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-4" }, [
              _c("div", {}, [
                _c("div", [_vm._v("Author")]),
                _c("div", { staticClass: "kt-font-bold" }, [
                  _vm._v(" " + _vm._s(_vm.change.author) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-4" }, [
              _c("div", { staticClass: "text-center" }, [
                _c("div", [_vm._v("Mark")]),
                _c(
                  "div",
                  {
                    staticClass: "kt-font-bold",
                    class: _vm.change.deleted ? "kt-font-danger" : "",
                  },
                  [_vm._v(" " + _vm._s(_vm.change.mark) + " ")]
                ),
              ]),
            ]),
            _vm.change.comment
              ? _c("div", { staticClass: "col-12 pt-2 pb-0 mb-0" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(" " + _vm._s(_vm.change.comment) + " "),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "text-muted" }, [
          _vm.change
            ? _c("span", [_vm._v(" " + _vm._s(_vm.change.timestamp) + " ")])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          {
            staticClass:
              "pull-right kt-pagination kt-pagination--info kt-pagination--sm",
          },
          [
            _c(
              "ul",
              { staticClass: "kt-pagination__links" },
              [
                _c(
                  "li",
                  {
                    class: `kt-pagination__link--next ${
                      _vm.selectedPageIndex == 0
                        ? "kt-pagination__link--disabled"
                        : ""
                    }`,
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.moveFirst.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-angle-left kt-font-info",
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._l(_vm.visibleChanges, function (item) {
                  return _c(
                    "li",
                    {
                      key: `item_${item.pageNumber}`,
                      class: item.active ? "kt-pagination__link--active" : "",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.selectedPageIndex = item.index
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.pageNumber) + " ")]
                      ),
                    ]
                  )
                }),
                _c(
                  "li",
                  {
                    class: `kt-pagination__link--prev ${
                      _vm.selectedPageIndex == _vm.gradeChanges.length - 1
                        ? "kt-pagination__link--disabled"
                        : ""
                    }`,
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.moveLast.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-angle-right kt-font-info",
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              2
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }