var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "google-import-modal",
    attrs: { "no-close-on-backdrop": "", size: "sm", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h3", { staticClass: "modal-title kt-font-primary" }, [
              _vm._v(" Google Classroom Assignment Import "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: { click: _vm.toggleShowGoogleImport },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { "max-height": "70vh", "overflow-y": "scroll" },
              },
              [
                _c(
                  "div",
                  { staticClass: "kt-portlet__body kt-portlet__body--fit" },
                  [
                    _c("div", { staticClass: "kt-section" }, [
                      _c("div", { staticClass: "kt-section__content" }, [
                        _c("table", { staticClass: "table table-striped" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "1%" } }, [
                                _vm._v(" # "),
                              ]),
                              _c("td", { staticStyle: { width: "20%" } }, [
                                _vm._v(" Title "),
                              ]),
                              _c("td", { staticStyle: { width: "10%" } }, [
                                _vm._v(" Max Points "),
                              ]),
                              _c("td", { staticStyle: { width: "12%" } }, [
                                _vm._v(" Marking Period "),
                              ]),
                              _c("td", { staticStyle: { width: "18%" } }, [
                                _vm._v(" Category "),
                              ]),
                              _c("td", { staticStyle: { width: "15%" } }, [
                                _vm._v(" Due Date "),
                              ]),
                              _c("td", { staticStyle: { width: "35%" } }, [
                                _vm._v(" Update Existing "),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              [..._vm.importedCourseWork],
                              function (assignment, idx) {
                                return _c(
                                  "tr",
                                  { key: `imported_google_assignment_${idx}` },
                                  [
                                    _c("td", [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "kt-checkbox kt-checkbox--tick kt-checkbox--success",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: assignment.checked,
                                                expression:
                                                  "assignment.checked",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                assignment.checked
                                              )
                                                ? _vm._i(
                                                    assignment.checked,
                                                    null
                                                  ) > -1
                                                : assignment.checked,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = assignment.checked,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        assignment,
                                                        "checked",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        assignment,
                                                        "checked",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    assignment,
                                                    "checked",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c("span"),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "span",
                                        { staticClass: "kt-widget11__title" },
                                        [_vm._v(_vm._s(assignment.title))]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: assignment.maxPoints,
                                            expression: "assignment.maxPoints",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid":
                                            _vm.invalidImport &&
                                            assignment.checked &&
                                            !assignment.maxPoints,
                                        },
                                        staticStyle: { width: "80%" },
                                        attrs: {
                                          disabled:
                                            !assignment.checked ||
                                            assignment.isExistingCourseWork,
                                          type: "text",
                                        },
                                        domProps: {
                                          value: assignment.maxPoints,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              assignment,
                                              "maxPoints",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                assignment.schoolTermMarkingPeriodId,
                                              expression:
                                                "assignment.schoolTermMarkingPeriodId",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.invalidImport &&
                                              assignment.checked &&
                                              !assignment.schoolTermMarkingPeriodId,
                                          },
                                          staticStyle: { width: "80%" },
                                          attrs: {
                                            disabled:
                                              !assignment.checked ||
                                              assignment.isExistingCourseWork,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                assignment,
                                                "schoolTermMarkingPeriodId",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.markingPeriodSelect,
                                          function (option) {
                                            return _c(
                                              "option",
                                              {
                                                key: option.value,
                                                domProps: {
                                                  value: option.value,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(option.text) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                assignment.gradeTemplateCategoryId,
                                              expression:
                                                "assignment.gradeTemplateCategoryId",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.invalidImport &&
                                              assignment.checked &&
                                              !assignment.gradeTemplateCategoryId,
                                          },
                                          staticStyle: { width: "80%" },
                                          attrs: {
                                            disabled:
                                              !assignment.checked ||
                                              assignment.isExistingCourseWork,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                assignment,
                                                "gradeTemplateCategoryId",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.gradeCategories,
                                          function (t) {
                                            return _c(
                                              "option",
                                              {
                                                key: `gradeTemplateCategories_${t.gradeTemplateCategoryId}`,
                                                domProps: {
                                                  value:
                                                    t.gradeTemplateCategoryId,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(t.categoryName) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        _c("b-form-input", {
                                          staticClass: "form-control kt-input",
                                          class: {
                                            "is-invalid":
                                              _vm.invalidImport &&
                                              assignment.checked &&
                                              !assignment.dueDate,
                                          },
                                          staticStyle: { width: "80%" },
                                          attrs: {
                                            type: "date",
                                            disabled:
                                              !assignment.checked ||
                                              assignment.isExistingCourseWork,
                                            autocomplete: "off",
                                          },
                                          model: {
                                            value: assignment.dueDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                assignment,
                                                "dueDate",
                                                $$v
                                              )
                                            },
                                            expression: "assignment.dueDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      {
                                        class: {
                                          "text-center":
                                            !_vm.hasExistingCourseWork,
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "row" }, [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    assignment.courseWorkId,
                                                  expression:
                                                    "assignment.courseWorkId",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              staticStyle: { width: "80%" },
                                              attrs: {
                                                disabled: !assignment.checked,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      assignment,
                                                      "courseWorkId",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.existingCourseWorkSelected(
                                                      $event,
                                                      idx
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { domProps: { value: null } },
                                                [
                                                  _vm._v(
                                                    " Import as new assignment "
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.filteredCourseWork,
                                                function (option) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: option.courseWorkId,
                                                      domProps: {
                                                        value:
                                                          option.courseWorkId,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            option.courseWorkTitle
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "mr-auto ml-4" }, [
              _c("div", { staticClass: "kt-font-bold row" }, [
                _c("span", { staticClass: "form-text mr-3" }, [
                  _vm._v(" Select "),
                ]),
                _c("span", { staticClass: "form-text text-muted" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectAll.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("All")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectNone.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("None")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectInvert.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Invert")]
                  ),
                ]),
              ]),
            ]),
            _c("div", [
              _vm.invalidImport
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          value: "Ensure all missing fields are entered",
                          expression: "'Ensure all missing fields are entered'",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "mr-3",
                    },
                    [
                      _c("i", {
                        staticClass:
                          "fa fa-exclamation-circle fa-2x mt-2 kt-font-danger",
                      }),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary alignment pull-right",
                  class: {
                    "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                  },
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    disabled: _vm.saving || _vm.noneSelected,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.importCourseWork.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" Save Assignments ")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }