var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-widget15" }, [
    _c("div", { staticClass: "kt-widget15__items" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "kt-widget15__item mt-2" }, [
            _c("div", { staticClass: "w-100 text-nowrap text-truncate" }, [
              _c("span", { staticClass: "kt-widget15__stats1" }, [
                _c("span", { class: `mr-2 font kt-font-bold` }, [
                  _vm._v(_vm._s(_vm.percentage) + "%"),
                ]),
                _c(
                  "span",
                  {
                    class: `kt-font kt-font-transform-u ml-3 ${_vm.labelColor} `,
                  },
                  [_vm._v(" " + _vm._s(_vm.label) + " ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "kt-space-5" }),
            _c(
              "div",
              { staticClass: "progress kt-widget15__chart-progress--sm mb-0" },
              [
                _c("div", {
                  class: `progress-bar ${_vm.color}`,
                  style: { width: `${_vm.percentage}%` },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": _vm.percentage,
                    "aria-valuemin": "0",
                    "aria-valuemax": "100",
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }