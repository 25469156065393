var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dataSource.length > 0
    ? _c(
        "div",
        { key: `key_${_vm.key}`, staticClass: "w-100 gb-hot-table-container" },
        [
          _c(
            "hot-table",
            { ref: "gbHotTable", attrs: { settings: _vm.hotSettings } },
            [
              _c(
                "hot-column",
                {
                  attrs: {
                    title: "Student",
                    width: 180,
                    "read-only": true,
                    settings: {
                      data: `student`,
                    },
                  },
                },
                [
                  _c("HotCellStudentDetails", {
                    attrs: { "hot-renderer": "" },
                  }),
                ],
                1
              ),
              _c(
                "hot-column",
                {
                  attrs: {
                    title: "Average",
                    width: 84,
                    "read-only": true,
                    settings: {
                      data: `student`,
                    },
                  },
                },
                [_c("HotCellGradeAverage", { attrs: { "hot-renderer": "" } })],
                1
              ),
              _vm._l(_vm.columns, function (column) {
                return _c("hot-column", {
                  key: column.key,
                  attrs: {
                    width: 112,
                    title: `${column.title}`,
                    settings: {
                      data: column.key,
                    },
                    renderer: _vm.markRenderer,
                  },
                })
              }),
            ],
            2
          ),
          _c("b-modal", {
            attrs: {
              "hide-header-close": true,
              "no-close-on-esc": true,
              "no-close-on-backdrop": true,
              centered: true,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-header",
                  fn: function () {
                    return [_c("h5", [_vm._v("A saving error occurred")])]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-body" }, [
                        _c("p", [
                          _vm._v(
                            " The following grades were not saved, please check your network connection, reload the gradebook and try again. "
                          ),
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.formattedInvalidGrades, function (grade) {
                            return _c(
                              "li",
                              {
                                key: `key_${grade.studentEnrollmentId}_${grade.courseWorkId}`,
                              },
                              [
                                grade.student
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(grade.student.lastName) +
                                          ", " +
                                          _vm._s(grade.student.firstName) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v(" Unknown Student ")]),
                                grade.courseWork
                                  ? _c("span", [
                                      _vm._v(
                                        " - " +
                                          _vm._s(
                                            grade.courseWork.courseWorkTitle
                                          ) +
                                          ", "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(" - Unknown Assignment, "),
                                    ]),
                                _c("span", [
                                  _vm._v(" Mark: " + _vm._s(grade.mark) + " "),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success pull-right",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.reload.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Reload ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2295431252
            ),
            model: {
              value: _vm.showErrors,
              callback: function ($$v) {
                _vm.showErrors = $$v
              },
              expression: "showErrors",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }