var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-widget20" }, [
    _c("div", { staticClass: "kt-widget20__content pt-0 mt-0" }, [
      _c("div", { staticClass: "media" }, [
        _c(
          "div",
          {
            class: `mr-3 align-self-center kt-badge kt-badge--lg scaleColorBadge kt-font-lg kt-font-bold ${_vm.color}`,
          },
          [_vm._v(" " + _vm._s(_vm.averageDisplay) + " ")]
        ),
        _c("div", { staticClass: "media-body" }, [
          _c(
            "span",
            { class: `kt-widget20__number font kt-font-lg ${_vm.color}` },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          ),
          _c("br"),
          _c("span", { staticClass: "kt-widget20__desc kt-font-bolder" }, [
            _vm._v(" " + _vm._s(_vm.percentage) + "% "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }