<template>
<div
    v-if="ready"
    :key="`avg_${key}_${course.courseSectionId}`"
    class="d-flex flex-column no-gutters align-items-center justify-content-center"
    style="height: 100%;"
>
    <div
        v-if="displayPreference === 'All' || displayPreference === 'ShowPercentage'"
        :class="`grade font ${color}` "
    >
        {{ markingPeriodAverage }}
    </div>
    <div
        v-if="displayPreference === 'All' || displayPreference === 'ShowScale'"
        :class="[`grade font ${color}`, {
            'mt-1': displayPreference === 'All',
        }] "
    >
        {{ mark }}
    </div>
    <span
        v-if="false"
        class="text-muted"
    >
        <!-- debugging timestamp to see repaint -->
        {{ String(Math.floor(Date.now() / 1000) || '').substr(-4) }}
    </span>
</div>
</template>

<script lang="ts">

import { getStudentInCourse } from '../store/mixins/studentMixins';

export default {
    name: 'HotCellGradeAverage',
    data() {
        return {
            key: 0,
            markingPeriodAverages: [],
            // the BaseEditorComponent class props
            hotInstance: null,
            TD: null,
            row: null,
            col: null,
            prop: null,
            value: null,
            cellProperties: null,
        };
    },
    computed: {
        dataSource() {
            return this.value;
        },
        studentEnrollmentId() {
            if (!this.dataSource) return null;
            return this.dataSource.studentEnrollmentId;
        },
        ready() {
            return Boolean(this.course && this.average);
        },
        course() {
            if (!this.dataSource) return null;
            return this.dataSource.courseSection;
        },
        gradeTemplate() {
            const { course } = this;
            if (!course) return null;
            return course.gradeTemplate;
        },
        displayPreference() {
            const { gradeTemplate } = this;
            if (!gradeTemplate) return null;
            return gradeTemplate.schoolScaledDisplayPreference;
        },
        courseSectionId() {
            if (!this.course) return null;
            return this.course.courseSectionId;
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        average() {
            const { schoolTermMarkingPeriodId } = this;
            if (!schoolTermMarkingPeriodId) return null;
            return this.markingPeriodAverages.find((a) => a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        color() {
            return this.average.color;
        },
        mark() {
            return this.average.mark;
        },
        markingPeriodAverage() {
            return round(this.average.markingPeriodAverage * 100, 2);
        },
        averageKey() {
            if (!this.dataSource) return null;
            const { schoolTermMarkingPeriodId, studentEnrollmentId, courseSectionId } = this;
            return `average_${studentEnrollmentId}_${courseSectionId}_${schoolTermMarkingPeriodId}`;
        },
        averageCounter() {
            const { averageKey } = this;
            if (!averageKey) return 0;
            return this.$store.state.database.gradebook.updatedAverages[averageKey] || 0;
        },
    },
    watch: {
        averageCounter() {
            const { studentEnrollmentId, averageCounter } = this;
            const { row, col } = this;
            if (averageCounter > 0) {
                window.syncGrades.log(`average repainted for studentEnrollmentId ${studentEnrollmentId} in row ${row} column ${col}, ${averageCounter} time(s)`, 'info', 'HotCellGradeAverage');
            }
            this.populate();
        },
        dataSource() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            if (!this.dataSource) return;
            const { studentEnrollmentId } = this.dataSource;
            const { courseSectionId } = this.course;

            // this lookup is expensive, so we'll cache the result
            const student = getStudentInCourse(this.$store.state.database, studentEnrollmentId, courseSectionId);
            if (student) {
                this.markingPeriodAverages = [...student.markingPeriodAverages];
            }
        },
    },
};

function round(value, decimals) {
    // @ts-ignore
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}
</script>

<style scoped>
.grade {
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
