var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("CourseSubHeader", {
        attrs: {
          "show-marking-period-picker": !_vm.importingGrades,
          "increment-key": _vm.incrementKey,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _vm.importingGrades
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            value: `This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`,
                            expression:
                              "`This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass:
                          "kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded py-2",
                        staticStyle: {
                          "max-height": "30px",
                          "align-self": "center",
                        },
                      },
                      [
                        _c("span", { staticClass: "kt-font-boldest" }, [
                          _vm._v("IMPORT PREVIEW"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn kt-subheader__btn btn-bold btn-upper mr-2",
                    class: {
                      "btn-label-primary": _vm.gradeBy === "Points",
                      "btn-label-success": _vm.gradeBy === "Percentages",
                    },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.gradeBy =
                          _vm.gradeBy === "Points" ? "Percentages" : "Points"
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.gradeBy) + " ")]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon kt-subheader__btn",
                    class: { active: _vm.showSortOptions },
                    attrs: { href: "#", title: "Show Sorting Options" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.toggleShowSortOptions.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-sort-alpha-down" })]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon kt-subheader__btn",
                    class: { active: _vm.headerRow },
                    attrs: { href: "#", title: "Show Gradebook Header" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.headerRow = !_vm.headerRow
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-columns" })]
                ),
                _vm.canEditGradebook
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottomleft",
                            modifiers: { hover: true, bottomleft: true },
                          },
                        ],
                        staticClass: "btn btn-icon kt-subheader__btn-primary",
                        attrs: { href: "#", title: "Student Grade Transfer" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.toggleShowGradeTransfer.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-sign-in-alt" })]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon kt-subheader__btn-primary",
                    attrs: { href: "#", title: "Gradebook Export / Import" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.toggleShowImportExport.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon-download-1" })]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.isPortfolioReady
        ? _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4",
            },
            [
              _vm.gradebookSource.length == 0
                ? _c("div", { staticClass: "col" }, [_vm._m(0)])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "kt-portlet p-0 m-0" },
                [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        variant: "transparent",
                        opacity: "1",
                        blur: "3px",
                        show: _vm.calculating,
                      },
                    },
                    [
                      _vm.isPortfolioReady
                        ? _c(
                            "div",
                            { staticClass: "kt-portlet__body p-0 m-0" },
                            [
                              _vm.gradebookSource.length > 0
                                ? _c("CourseGradebookHotTable", {
                                    key: `key_${_vm.key}_${_vm.tableKey}`,
                                    ref: "hotContainer",
                                    attrs: {
                                      course: _vm.course,
                                      "grade-template": _vm.gradeTemplate,
                                      assignments: _vm.courseWork,
                                      "grouped-assignments":
                                        _vm.filteredAssignments,
                                      "data-source": _vm.gradebookSource,
                                      "import-source": _vm.importSource,
                                      "add-to-import-source":
                                        _vm.addToImportSource,
                                      "importing-grades": _vm.importingGrades,
                                      "header-row": _vm.headerRow,
                                      comments: _vm.comments,
                                      "set-course-work-re-order":
                                        _vm.setCourseWorkReOrder,
                                    },
                                  })
                                : _vm._e(),
                              _vm.hasActiveCell
                                ? _c("TheGradebookFloatingPortlet", {
                                    attrs: {
                                      show: _vm.showFloatingPortlet,
                                      course: _vm.course,
                                      teacher: _vm.teacher,
                                      "toggle-show-floating-portlet":
                                        _vm.toggleShowFloatingPortlet,
                                      "floating-export-import-open":
                                        _vm.showImportExport,
                                      "floating-sort-open": _vm.showSortOptions,
                                    },
                                  })
                                : _vm._e(),
                              _vm.showSortOptions
                                ? _c("TheGradebookFloatingSortOptions", {
                                    attrs: {
                                      show: _vm.showSortOptions,
                                      "toggle-show-sort-options":
                                        _vm.toggleShowSortOptions,
                                      "sort-by": _vm.sortBy,
                                      "sort-direction": _vm.sortDirection,
                                      "set-sort-by": _vm.setSortBy,
                                      "set-sort-direction":
                                        _vm.setSortDirection,
                                      "floating-": _vm.showFloatingPortlet,
                                      "floating-export-import-open":
                                        _vm.showImportExport,
                                    },
                                  })
                                : _vm._e(),
                              _vm.showImportExport
                                ? _c("TheGradebookFloatingExportImport", {
                                    attrs: {
                                      show: _vm.showImportExport,
                                      "toggle-show-import-export":
                                        _vm.toggleShowImportExport,
                                      "export-data": _vm.exportData,
                                      "import-data": _vm.importData,
                                      "import-google-data":
                                        _vm.importGoogleData,
                                      "save-imported-data":
                                        _vm.saveImportedData,
                                      "cancel-import": _vm.cancelImport,
                                      "importing-grades": _vm.importingGrades,
                                      saving: _vm.saving,
                                      "can-edit-gradebook":
                                        _vm.canEditGradebook,
                                      "has-grades":
                                        _vm.gradebookSource.length > 0,
                                    },
                                  })
                                : _vm._e(),
                              _vm.showGradeTransfer
                                ? _c("CourseGradeTransferModal", {
                                    attrs: {
                                      show: _vm.showGradeTransfer,
                                      "toggle-show-grade-transfer":
                                        _vm.toggleShowGradeTransfer,
                                      course: _vm.course,
                                      "increment-key": _vm.incrementTableKey,
                                    },
                                  })
                                : _vm._e(),
                              _c("TheGradebookGoogleImportModal", {
                                ref: "googleImportModal",
                                attrs: {
                                  show: _vm.showGoogleImport,
                                  "google-import-data": _vm.googleImportData,
                                  students: _vm.students,
                                  course: _vm.course,
                                  "course-work": _vm.courseWork,
                                  "gradebook-source": _vm.gradebookSource,
                                  "set-import-source": _vm.setImportSource,
                                  "set-importing-grades":
                                    _vm.setImportingGrades,
                                  "toggle-show-google-import":
                                    _vm.toggleShowGoogleImport,
                                  "toggle-show-import-export":
                                    _vm.toggleShowImportExport,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "alert alert-light alert-elevate fade show",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "la la-info-circle kt-font-danger" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " There are no grades or assignments found. Check the course assignments or roster. "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }