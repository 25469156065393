var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showCreateSchoolAnnouncement,
          expression: "showCreateSchoolAnnouncement",
        },
      ],
      ref: "schoolAnnouncementContainer",
      staticClass: "grouped-modal",
    },
    [
      _c("div", { staticClass: "d-flex flex-row" }, [
        _vm.showRecipientsList
          ? _c(
              "div",
              {
                staticClass:
                  "modal modal-sticky-bottom-right modal-sticky-lg show mx-1",
                staticStyle: {
                  display: "inline-block",
                  overflow: "visible",
                  position: "relative",
                },
                style: {
                  maxWidth: _vm.hasRecipients ? "36vw" : "28vw",
                  width: _vm.hasRecipients ? "36vw" : "28vw",
                },
                attrs: {
                  role: "dialog",
                  "data-backdrop": "false",
                  "aria-modal": "true",
                },
              },
              [
                _c(
                  "b-overlay",
                  {
                    staticStyle: { height: "100%" },
                    attrs: {
                      variant: "transparent",
                      center: "",
                      opacity: "1",
                      blur: "10px",
                      show: _vm.encryptionEnabled,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-dialog",
                        staticStyle: { height: "100%" },
                        attrs: { role: "document" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "modal-content",
                            staticStyle: { height: "100%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "kt-portlet kt-portlet--mobile",
                                staticStyle: { height: "100%" },
                              },
                              [
                                _c("div", { staticClass: "kt-portlet__head" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-portlet__head-label" },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "kt-portlet__head-title",
                                        },
                                        [_vm._v(" Select Recipients ")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-portlet__body",
                                    staticStyle: { height: "100%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "kt-widget4 kt-scroll",
                                        staticStyle: {
                                          height: "52vh",
                                          "overflow-x": "hidden",
                                          "overflow-y": "auto",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "kt-widget4__item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "kt-widget4__pic kt-widget4__pic--pic d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "kt-checkbox kt-checkbox--solid kt-checkbox--single mt-2",
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.newAnnouncement
                                                            .isSchoolAnnouncement,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.setSchoolAnnouncement(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("span"),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "btn btn-icon",
                                                    class: ["Purple", `btn-sm`],
                                                    staticStyle: {
                                                      cursor:
                                                        "default !important",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "course-icon",
                                                        style: [
                                                          { color: "#007bff" },
                                                        ],
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-school",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "kt-widget4__info",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__username kt-font-bolder",
                                                  },
                                                  [_vm._v(" Entire School ")]
                                                ),
                                              ]
                                            ),
                                            _vm.newAnnouncement
                                              .isSchoolAnnouncement
                                              ? _c(
                                                  "div",
                                                  { staticClass: "col-7" },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Send to"),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "kt-checkbox-inline",
                                                      },
                                                      [
                                                        _vm.userPermissions
                                                          .canMessageSchoolUsers
                                                          ? _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "kt-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .newAnnouncement
                                                                          .includeStaff,
                                                                      expression:
                                                                        "newAnnouncement.includeStaff",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm
                                                                          .newAnnouncement
                                                                          .includeStaff
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm
                                                                              .newAnnouncement
                                                                              .includeStaff,
                                                                            null
                                                                          ) > -1
                                                                        : _vm
                                                                            .newAnnouncement
                                                                            .includeStaff,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm
                                                                              .newAnnouncement
                                                                              .includeStaff,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement,
                                                                                "includeStaff",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement,
                                                                                "includeStaff",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.newAnnouncement,
                                                                            "includeStaff",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "Staff "
                                                                ),
                                                                _c("span"),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.userPermissions
                                                          .canMessageHomeUsers
                                                          ? _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "kt-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .newAnnouncement
                                                                          .includeStudents,
                                                                      expression:
                                                                        "newAnnouncement.includeStudents",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm
                                                                          .newAnnouncement
                                                                          .includeStudents
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm
                                                                              .newAnnouncement
                                                                              .includeStudents,
                                                                            null
                                                                          ) > -1
                                                                        : _vm
                                                                            .newAnnouncement
                                                                            .includeStudents,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm
                                                                              .newAnnouncement
                                                                              .includeStudents,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement,
                                                                                "includeStudents",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement,
                                                                                "includeStudents",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.newAnnouncement,
                                                                            "includeStudents",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "Students "
                                                                ),
                                                                _c("span"),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.userPermissions
                                                          .canMessageHomeUsers
                                                          ? _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "kt-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .newAnnouncement
                                                                          .includeGuardians,
                                                                      expression:
                                                                        "newAnnouncement.includeGuardians",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm
                                                                          .newAnnouncement
                                                                          .includeGuardians
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm
                                                                              .newAnnouncement
                                                                              .includeGuardians,
                                                                            null
                                                                          ) > -1
                                                                        : _vm
                                                                            .newAnnouncement
                                                                            .includeGuardians,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm
                                                                              .newAnnouncement
                                                                              .includeGuardians,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement,
                                                                                "includeGuardians",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement,
                                                                                "includeGuardians",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.newAnnouncement,
                                                                            "includeGuardians",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "Guardians "
                                                                ),
                                                                _c("span"),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.gradeLevels,
                                          function (gradeLevel, idx) {
                                            return _c(
                                              "div",
                                              {
                                                key: `gradeLevel_${idx}`,
                                                staticClass: "kt-widget4__item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__pic kt-widget4__pic--pic d-flex align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "kt-checkbox kt-checkbox--solid kt-checkbox--single mt-2",
                                                      },
                                                      [
                                                        _c("input", {
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked: Boolean(
                                                              _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                (g) =>
                                                                  g.gradeLevel ==
                                                                  gradeLevel
                                                              )
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.gradeLevelChecked(
                                                                $event,
                                                                gradeLevel
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("span"),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "btn btn-icon",
                                                        class: [
                                                          "Blue",
                                                          `btn-sm`,
                                                        ],
                                                        staticStyle: {
                                                          cursor:
                                                            "default !important",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "course-icon",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#007bff",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  gradeLevel
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__info",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__username",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(gradeLevel) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__text",
                                                      },
                                                      [_vm._v(" Grade Level ")]
                                                    ),
                                                  ]
                                                ),
                                                Boolean(
                                                  _vm.newAnnouncement.groupings.gradeLevels.find(
                                                    (g) =>
                                                      g.gradeLevel == gradeLevel
                                                  )
                                                )
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "col-7" },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Send to"),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-checkbox-inline",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "kt-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                          (g) =>
                                                                            g.gradeLevel ==
                                                                            gradeLevel
                                                                        )
                                                                          .includeStudents,
                                                                      expression:
                                                                        "newAnnouncement.groupings.gradeLevels.find((g) => g.gradeLevel == gradeLevel).includeStudents",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                          (g) =>
                                                                            g.gradeLevel ==
                                                                            gradeLevel
                                                                        )
                                                                          .includeStudents
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                              (
                                                                                g
                                                                              ) =>
                                                                                g.gradeLevel ==
                                                                                gradeLevel
                                                                            )
                                                                              .includeStudents,
                                                                            null
                                                                          ) > -1
                                                                        : _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                            (
                                                                              g
                                                                            ) =>
                                                                              g.gradeLevel ==
                                                                              gradeLevel
                                                                          )
                                                                            .includeStudents,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                              (
                                                                                g
                                                                              ) =>
                                                                                g.gradeLevel ==
                                                                                gradeLevel
                                                                            ).includeStudents,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                                  (
                                                                                    g
                                                                                  ) =>
                                                                                    g.gradeLevel ==
                                                                                    gradeLevel
                                                                                ),
                                                                                "includeStudents",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                                  (
                                                                                    g
                                                                                  ) =>
                                                                                    g.gradeLevel ==
                                                                                    gradeLevel
                                                                                ),
                                                                                "includeStudents",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                              (
                                                                                g
                                                                              ) =>
                                                                                g.gradeLevel ==
                                                                                gradeLevel
                                                                            ),
                                                                            "includeStudents",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "Students "
                                                                ),
                                                                _c("span"),
                                                              ]
                                                            ),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "kt-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                          (g) =>
                                                                            g.gradeLevel ==
                                                                            gradeLevel
                                                                        )
                                                                          .includeGuardians,
                                                                      expression:
                                                                        "newAnnouncement.groupings.gradeLevels.find((g) => g.gradeLevel == gradeLevel).includeGuardians",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                          (g) =>
                                                                            g.gradeLevel ==
                                                                            gradeLevel
                                                                        )
                                                                          .includeGuardians
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                              (
                                                                                g
                                                                              ) =>
                                                                                g.gradeLevel ==
                                                                                gradeLevel
                                                                            )
                                                                              .includeGuardians,
                                                                            null
                                                                          ) > -1
                                                                        : _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                            (
                                                                              g
                                                                            ) =>
                                                                              g.gradeLevel ==
                                                                              gradeLevel
                                                                          )
                                                                            .includeGuardians,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                              (
                                                                                g
                                                                              ) =>
                                                                                g.gradeLevel ==
                                                                                gradeLevel
                                                                            ).includeGuardians,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                                  (
                                                                                    g
                                                                                  ) =>
                                                                                    g.gradeLevel ==
                                                                                    gradeLevel
                                                                                ),
                                                                                "includeGuardians",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                                  (
                                                                                    g
                                                                                  ) =>
                                                                                    g.gradeLevel ==
                                                                                    gradeLevel
                                                                                ),
                                                                                "includeGuardians",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.newAnnouncement.groupings.gradeLevels.find(
                                                                              (
                                                                                g
                                                                              ) =>
                                                                                g.gradeLevel ==
                                                                                gradeLevel
                                                                            ),
                                                                            "includeGuardians",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "Guardians "
                                                                ),
                                                                _c("span"),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._l(
                                          _vm.homeRooms,
                                          function (homeRoom, idx) {
                                            return _c(
                                              "div",
                                              {
                                                key: `homeRoom_${idx}`,
                                                staticClass: "kt-widget4__item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__pic kt-widget4__pic--pic d-flex align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "kt-checkbox kt-checkbox--solid kt-checkbox--single mt-2",
                                                      },
                                                      [
                                                        _c("input", {
                                                          attrs: {
                                                            type: "checkbox",
                                                            disabled:
                                                              _vm
                                                                .newAnnouncement
                                                                .isSchoolAnnouncement,
                                                          },
                                                          domProps: {
                                                            checked: Boolean(
                                                              _vm.newAnnouncement.groupings.homeRooms.find(
                                                                (h) =>
                                                                  h.homeRoom ==
                                                                  homeRoom
                                                              )
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.homeRoomChecked(
                                                                $event,
                                                                homeRoom
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("span"),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "btn btn-icon",
                                                        class: [
                                                          "Blue",
                                                          `btn-sm`,
                                                        ],
                                                        staticStyle: {
                                                          cursor:
                                                            "default !important",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "course-icon",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#007bff",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  homeRoom.substring(
                                                                    0,
                                                                    1
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__info",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__username",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(homeRoom) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__text",
                                                      },
                                                      [_vm._v(" Homeroom ")]
                                                    ),
                                                  ]
                                                ),
                                                Boolean(
                                                  _vm.newAnnouncement.groupings.homeRooms.find(
                                                    (h) =>
                                                      h.homeRoom == homeRoom
                                                  )
                                                )
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "col-7" },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Send to"),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-checkbox-inline",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "kt-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.newAnnouncement.groupings.homeRooms.find(
                                                                          (h) =>
                                                                            h.homeRoom ==
                                                                            homeRoom
                                                                        )
                                                                          .includeStudents,
                                                                      expression:
                                                                        "newAnnouncement.groupings.homeRooms.find((h) => h.homeRoom == homeRoom).includeStudents",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.newAnnouncement.groupings.homeRooms.find(
                                                                          (h) =>
                                                                            h.homeRoom ==
                                                                            homeRoom
                                                                        )
                                                                          .includeStudents
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.newAnnouncement.groupings.homeRooms.find(
                                                                              (
                                                                                h
                                                                              ) =>
                                                                                h.homeRoom ==
                                                                                homeRoom
                                                                            )
                                                                              .includeStudents,
                                                                            null
                                                                          ) > -1
                                                                        : _vm.newAnnouncement.groupings.homeRooms.find(
                                                                            (
                                                                              h
                                                                            ) =>
                                                                              h.homeRoom ==
                                                                              homeRoom
                                                                          )
                                                                            .includeStudents,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.newAnnouncement.groupings.homeRooms.find(
                                                                              (
                                                                                h
                                                                              ) =>
                                                                                h.homeRoom ==
                                                                                homeRoom
                                                                            ).includeStudents,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.homeRooms.find(
                                                                                  (
                                                                                    h
                                                                                  ) =>
                                                                                    h.homeRoom ==
                                                                                    homeRoom
                                                                                ),
                                                                                "includeStudents",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.homeRooms.find(
                                                                                  (
                                                                                    h
                                                                                  ) =>
                                                                                    h.homeRoom ==
                                                                                    homeRoom
                                                                                ),
                                                                                "includeStudents",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.newAnnouncement.groupings.homeRooms.find(
                                                                              (
                                                                                h
                                                                              ) =>
                                                                                h.homeRoom ==
                                                                                homeRoom
                                                                            ),
                                                                            "includeStudents",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "Students "
                                                                ),
                                                                _c("span"),
                                                              ]
                                                            ),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "kt-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.newAnnouncement.groupings.homeRooms.find(
                                                                          (h) =>
                                                                            h.homeRoom ==
                                                                            homeRoom
                                                                        )
                                                                          .includeGuardians,
                                                                      expression:
                                                                        "newAnnouncement.groupings.homeRooms.find((h) => h.homeRoom == homeRoom).includeGuardians",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.newAnnouncement.groupings.homeRooms.find(
                                                                          (h) =>
                                                                            h.homeRoom ==
                                                                            homeRoom
                                                                        )
                                                                          .includeGuardians
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.newAnnouncement.groupings.homeRooms.find(
                                                                              (
                                                                                h
                                                                              ) =>
                                                                                h.homeRoom ==
                                                                                homeRoom
                                                                            )
                                                                              .includeGuardians,
                                                                            null
                                                                          ) > -1
                                                                        : _vm.newAnnouncement.groupings.homeRooms.find(
                                                                            (
                                                                              h
                                                                            ) =>
                                                                              h.homeRoom ==
                                                                              homeRoom
                                                                          )
                                                                            .includeGuardians,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.newAnnouncement.groupings.homeRooms.find(
                                                                              (
                                                                                h
                                                                              ) =>
                                                                                h.homeRoom ==
                                                                                homeRoom
                                                                            ).includeGuardians,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.homeRooms.find(
                                                                                  (
                                                                                    h
                                                                                  ) =>
                                                                                    h.homeRoom ==
                                                                                    homeRoom
                                                                                ),
                                                                                "includeGuardians",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.newAnnouncement.groupings.homeRooms.find(
                                                                                  (
                                                                                    h
                                                                                  ) =>
                                                                                    h.homeRoom ==
                                                                                    homeRoom
                                                                                ),
                                                                                "includeGuardians",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.newAnnouncement.groupings.homeRooms.find(
                                                                              (
                                                                                h
                                                                              ) =>
                                                                                h.homeRoom ==
                                                                                homeRoom
                                                                            ),
                                                                            "includeGuardians",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "Guardians "
                                                                ),
                                                                _c("span"),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "modal modal-sticky-bottom-right modal-sticky-lg show mx-1",
            staticStyle: {
              display: "block",
              overflow: "visible",
              position: "relative",
              width: "40vw",
            },
            attrs: {
              id: "kt_inbox_compose",
              role: "dialog",
              "data-backdrop": "false",
              "aria-modal": "true",
            },
          },
          [
            _c(
              "b-overlay",
              {
                attrs: {
                  variant: "transparent",
                  "no-center": "",
                  opacity: "1",
                  blur: "10px",
                  show: _vm.encryptionEnabled,
                },
                scopedSlots: _vm._u([
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "pull-right mr-2 mt-2 kt-font-lg" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-icon btn-clean",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.closeModal.apply(null, arguments)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "flaticon2-cross" })]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-center pt-4 kt-font-lg" },
                          [
                            _vm._v(
                              " PII is currently encrypted. Please unlock PII to send an announcement. "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "text-center pt-4" }, [
                          _vm.encryptionEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-label-brand btn-bold btn-upper mt-4",
                                  staticStyle: { "min-width": "160px" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.decrypt()
                                    },
                                  },
                                },
                                [_vm._v(" Unlock PII ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-dialog", attrs: { role: "document" } },
                  [
                    _c("div", { staticClass: "modal-content kt-inbox" }, [
                      _c(
                        "div",
                        {
                          staticClass: "kt-inbox__form",
                          attrs: { id: "kt_inbox_compose_form" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "kt-inbox__head",
                              on: { mousedown: _vm.dragMouseDown },
                            },
                            [
                              _c("div", { staticClass: "kt-inbox__title" }, [
                                _vm._v(" New School Announcement "),
                              ]),
                              _c("div", { staticClass: "kt-inbox__actions" }, [
                                _vm.hasContent ||
                                _vm.hasRecipients ||
                                _vm.files.length
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            value: "Discard message",
                                            expression: "'Discard message'",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        staticClass:
                                          "kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.clearMessage.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "flaticon2-rubbish-bin-delete-button",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-icon btn-clean btn-icon-md",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.toggleSize.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      class: [
                                        "la",
                                        _vm.minimized
                                          ? "la-angle-up"
                                          : "la-angle-down",
                                      ],
                                    }),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.closeModal.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "flaticon2-cross" })]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "kt-inbox__body",
                              class: [_vm.minimized ? "d-none" : ""],
                              staticStyle: { position: "relative" },
                            },
                            [
                              _c("div", { staticClass: "kt-inbox__to" }, [
                                _c(
                                  "div",
                                  { staticClass: "kt-inbox__wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-inbox__field kt-inbox__field--to",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "kt-inbox__label" },
                                          [_vm._v(" To: ")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "kt-link kt-link--state kt-link--primary",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                _vm.showRecipientsList =
                                                  !_vm.showRecipientsList
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.newAnnouncement
                                                    .isSchoolAnnouncement
                                                    ? "1"
                                                    : `${
                                                        _vm.newAnnouncement
                                                          .groupings.gradeLevels
                                                          .length +
                                                        _vm.newAnnouncement
                                                          .groupings.homeRooms
                                                          .length
                                                      }`
                                                ) +
                                                " Recipients "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-inbox__subject ql-toolbar ql-snow",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newAnnouncement.subject,
                                        expression: "newAnnouncement.subject",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { placeholder: "Subject" },
                                    domProps: {
                                      value: _vm.newAnnouncement.subject,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.newAnnouncement,
                                          "subject",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-inbox__editor ql-container ql-snow px-3 pt-3",
                                  staticStyle: { height: "38vh" },
                                  attrs: { id: "kt_inbox_compose_editor" },
                                },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newAnnouncement.body,
                                        expression: "newAnnouncement.body",
                                      },
                                    ],
                                    staticClass: "w-100 h-100",
                                    staticStyle: {
                                      border: "none !important",
                                      outline: "none !important",
                                      resize: "none !important",
                                    },
                                    style: [
                                      _vm.files.length
                                        ? {
                                            paddingBottom: `${
                                              _vm.files.length * 40
                                            }px`,
                                          }
                                        : {},
                                    ],
                                    attrs: { placeholder: "Type message..." },
                                    domProps: {
                                      value: _vm.newAnnouncement.body,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.newAnnouncement,
                                          "body",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "w-100 px-3",
                                  staticStyle: {
                                    position: "absolute",
                                    bottom: "5px",
                                  },
                                },
                                _vm._l(_vm.files, function (file, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: `attachedFile_${idx}`,
                                      staticClass:
                                        "attached-file px-3 py-1 my-2 d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-link kt-link--state kt-link--primary kt-font-bolder no-wrap",
                                        },
                                        [_vm._v(" " + _vm._s(file.name) + " ")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "kt-link kt-link--state kt-link--danger kt-font-bolder ml-auto",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              _vm.files = _vm.files.filter(
                                                (f, i) => i !== idx
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "flaticon2-cross",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "kt-inbox__foot",
                              class: [_vm.minimized ? "d-none" : ""],
                            },
                            [
                              _c("div", { staticClass: "kt-inbox__primary" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-brand btn-bold mr-3",
                                    class: {
                                      "kt-spinner  kt-spinner--sm kt-spinner--light":
                                        _vm.saving,
                                    },
                                    attrs: {
                                      type: "button",
                                      disabled: !_vm.canSend || _vm.saving,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.sendAnnouncement.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Send ")]
                                ),
                                _vm.canSend &&
                                _vm.newAnnouncement.isSchoolAnnouncement
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-font-warning kt-font-bolder",
                                        },
                                        [
                                          _vm._v(
                                            " Announcements are irreversible once sent. "
                                          ),
                                        ]
                                      ),
                                      _vm.newAnnouncement.smsEnabled &&
                                      _vm.canToggleSMS
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  value:
                                                    _vm.characterCount > 2400
                                                      ? "Any text past 2400 characters will not be sent with SMS"
                                                      : _vm.characterCount >
                                                        1200
                                                      ? "SMS will be sent as 2 separate messages"
                                                      : "",
                                                  expression:
                                                    "characterCount > 2400 ? 'Any text past 2400 characters will not be sent with SMS' : characterCount > 1200 ? 'SMS will be sent as 2 separate messages' : ''",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              class: {
                                                "kt-font-warning kt-font-bolder":
                                                  _vm.characterCount > 1200 &&
                                                  _vm.characterCount <= 2400,
                                                "kt-font-danger kt-font-bolder":
                                                  _vm.characterCount > 2400,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.characterCount) +
                                                  " / 2400 characters for SMS "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm.newAnnouncement.smsEnabled &&
                                    _vm.canToggleSMS
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            value:
                                              _vm.characterCount > 2400
                                                ? "Any text past 2400 characters will not be sent with SMS"
                                                : _vm.characterCount > 1200
                                                ? "SMS will be sent as 2 separate messages"
                                                : "",
                                            expression:
                                              "characterCount > 2400 ? 'Any text past 2400 characters will not be sent with SMS' : characterCount > 1200 ? 'SMS will be sent as 2 separate messages' : ''",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        class: {
                                          "kt-font-warning kt-font-bolder":
                                            _vm.characterCount > 1200 &&
                                            _vm.characterCount <= 2400,
                                          "kt-font-danger kt-font-bolder":
                                            _vm.characterCount > 2400,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.characterCount) +
                                            " / 2400 characters for SMS "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "kt-inbox__secondary" },
                                [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          value:
                                            "Allow recipients to reply to message",
                                          expression:
                                            "'Allow recipients to reply to message'",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass: "btn btn-bold btn-icon mx-1",
                                      class: {
                                        "btn-outline-success":
                                          !_vm.newAnnouncement.allowReplies,
                                        "btn-success":
                                          _vm.newAnnouncement.allowReplies,
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.newAnnouncement.allowReplies =
                                            !_vm.newAnnouncement.allowReplies
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-reply" })]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          value: _vm.subscription
                                            ? _vm.subscription.emailQuota !==
                                                null &&
                                              _vm.subscription.emailUsage >=
                                                _vm.subscription.emailQuota
                                              ? "Your school's email limit has been exceeded"
                                              : "Notify email of recipients"
                                            : "Notify email of recipients",
                                          expression:
                                            "subscription ? subscription.emailQuota !== null&& (subscription.emailUsage >= subscription.emailQuota) ? 'Your school\\'s email limit has been exceeded' : 'Notify email of recipients' : 'Notify email of recipients'",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                    },
                                    [
                                      _vm.canToggleEmail
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-bold btn-icon mx-1",
                                              class: {
                                                "btn-outline-success":
                                                  !_vm.newAnnouncement
                                                    .notifyEmail,
                                                "btn-success":
                                                  _vm.newAnnouncement
                                                    .notifyEmail,
                                              },
                                              attrs: {
                                                type: "button",
                                                disabled: _vm.subscription
                                                  ? _vm.subscription
                                                      .emailQuota !== null &&
                                                    _vm.subscription
                                                      .emailUsage >=
                                                      _vm.subscription
                                                        .emailQuota
                                                  : false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.newAnnouncement.notifyEmail =
                                                    !_vm.newAnnouncement
                                                      .notifyEmail
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "flaticon2-new-email",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          value: _vm.subscription
                                            ? _vm.subscription.smsQuota !==
                                                null &&
                                              _vm.subscription.smsUsage >=
                                                _vm.subscription.smsQuota
                                              ? "Your school's SMS limit has been exceeded"
                                              : "Send SMS to recipients"
                                            : "Send SMS to recipients",
                                          expression:
                                            "subscription ? subscription.smsQuota !== null && (subscription.smsUsage >= subscription.smsQuota) ? 'Your school\\'s SMS limit has been exceeded' : 'Send SMS to recipients' : 'Send SMS to recipients'",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                    },
                                    [
                                      _vm.canToggleSMS
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-bold btn-icon mx-1",
                                              class: {
                                                "btn-outline-warning":
                                                  !_vm.newAnnouncement
                                                    .smsEnabled,
                                                "btn-warning":
                                                  _vm.newAnnouncement
                                                    .smsEnabled,
                                              },
                                              attrs: {
                                                type: "button",
                                                disabled: _vm.subscription
                                                  ? _vm.subscription
                                                      .smsQuota !== null &&
                                                    _vm.subscription.smsUsage >=
                                                      _vm.subscription.smsQuota
                                                  : false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.newAnnouncement.smsEnabled =
                                                    !_vm.newAnnouncement
                                                      .smsEnabled
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-mobile-alt",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("div", [
                                    _c("input", {
                                      staticStyle: { display: "none" },
                                      attrs: {
                                        id: "file-input",
                                        type: "file",
                                        name: "file",
                                      },
                                      on: { change: _vm.onFileBrowseSelect },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            value:
                                              "Attach up to 3 files to message",
                                            expression:
                                              "'Attach up to 3 files to message'",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        staticClass:
                                          "btn btn-bold btn-icon btn-clean btn-clean-dark mx-1",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.files.length >= 3,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.triggerFileBrowser.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-paperclip",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }