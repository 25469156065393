var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.decimalToPercent(_vm.markingPeriodAverage.markingPeriodAverage) !==
    null
    ? _c("div", { staticClass: "kt-widget20" }, [
        _c(
          "div",
          { staticClass: "kt-widget20__content kt-portlet__space-x pb-0" },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("StudentMarkingPeriodAverage", {
                    attrs: {
                      percentage: _vm.decimalToPercent(
                        _vm.markingPeriodAverage.markingPeriodAverage
                      ),
                      color: _vm.markingPeriodAverage.color,
                      label: `MP${_vm.markingPeriodAverage.markingPeriod.markingPeriod} Avg`,
                      "average-display": _vm.markingPeriodAverage.mark,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "media",
                    style: {
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    },
                  },
                  [
                    _c(
                      "UserAvatar",
                      {
                        staticClass: "kt-widget7__img mx-3 my-0",
                        staticStyle: { "max-height": "38px" },
                        attrs: { "avatar-user": _vm.student },
                      },
                      [
                        _c("div", { staticClass: "kt-media" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold",
                            },
                            [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { class: "media-body" }, [
                      _c(
                        "div",
                        { staticClass: "text-nowrap text-truncate" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "kt-widget5__title pb-0 w-100",
                              attrs: {
                                event: "",
                                to: {
                                  name: "StudentCourseOverview",
                                  params: {
                                    studentEnrollmentId:
                                      _vm.studentEnrollmentId,
                                    extCourseSectionId:
                                      _vm.course.extCourseSectionId,
                                  },
                                },
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openPanelForStudent(_vm.student)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.student.lastName) +
                                  ", " +
                                  _vm._s(_vm.student.firstName) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              class: `kt-font kt-font-transform-u ${_vm.courseWork.color} `,
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.courseWork.categoryName) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.categories, function (c) {
                return _c(
                  "div",
                  { key: c.categoryName, staticClass: "col-4" },
                  [
                    c.scaled && _vm.decimalToPercent(c.percentEarned) !== null
                      ? _c("StudentMarkingPeriodAverageTotals", {
                          attrs: {
                            percentage: _vm.decimalToPercent(c.percentEarned),
                            color: c.scaled.color,
                            "label-color": c.color,
                            label: c.categoryName,
                            "average-display": c.scaled.mark,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "kt-widget20__chart" },
          [
            _c("GradebookFooterLineChart", {
              key: `${_vm.courseSectionId}_${_vm.studentEnrollmentId}_${_vm.schoolTermMarkingPeriodId}_${_vm.selectedGradingCategory}`,
              attrs: {
                height: 40,
                assignments: _vm.assignments,
                category: _vm.category,
                "clicked-assignment": _vm.openPanelForCourseWork,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }